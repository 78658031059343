<template>
    <div>
        <v-toolbar color="transparent" flat>
            <v-btn icon data-cy="btn-back" @click="$router.push({ name: 'admin-leads' })">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>
                <h1 class="text-h6">
                    {{ $t("AdminLeadEdit.title") }}: {{ item.id }}
                </h1>
            </v-toolbar-title>
        </v-toolbar>

        <v-card :loading="dataLoading" :title="personCardTitle" class="mb-4">
            <template #append>
                <div class="d-flex ga-2 align-center">
                    <v-chip
                        v-if="item.ngo_is_visible === true"
                        data-cy="chip-visibility-visible"
                        color="primary"
                        label
                        prepend-icon="mdi-eye"
                        class="ml-2"
                        size="small"
                    >
                        {{ $t('AdminLeadEdit.visibilityChip.isVisible') }}
                    </v-chip>
                    <v-chip
                        v-if="item.ngo_is_visible === false"
                        color="primary"
                        label
                        prepend-icon="mdi-eye-off"
                        class="ml-2"
                        size="small"
                        data-cy="chip-visibility-hidden"
                    >
                        {{ $t('AdminLeadEdit.visibilityChip.isHidden') }}
                    </v-chip>
                    <lead-action-menu :item="item" @update:toggle="updateToggle" @error="errorToggle" />
                </div>
            </template>
            <v-card-text>
                <template v-if="hasNgoThanksExternal">
                    <v-alert v-if="ngoThanksExternal" class="mb-8" text type="info">
                        {{ $t("AdminTestator.ngoThanksExternal") }}
                    </v-alert>
                    <v-alert
                        v-else-if="isNgoThanks(item)"
                        class="mb-8"
                        text
                        type="success"
                    >
                        {{ $t("AdminTestator.ngoThanksTrue") }}
                    </v-alert>
                    <v-alert
                        v-else-if="!item.anonymized"
                        class="mb-8"
                        icon="mdi-minus-circle-outline"
                        text
                        type="error"
                    >
                        {{ $t("AdminTestator.ngoThanksFalse") }}
                    </v-alert>
                </template>

                <template v-else>
                    <template
                        v-if="item.is_favored && (item.lead_type === 'will' || item.lead_type === 'consulting')"
                    >
                        <v-alert v-if="ngoThanks" class="mb-4" text type="success">
                            <span
                                class="d-block font-bold leading-relaxed"
                                v-html="$t('AdminLeadEdit.ngoThanks.title')"
                            />
                            <span
                                v-html="
                                    $t('AdminLeadEdit.ngoThanks.true', {
                                        btn_label_text: $t(
                                            'Affiliate.ngoThanks.general.acceptLabel'
                                        ),
                                    })
                                "
                            />
                        </v-alert>
                        <v-alert v-else class="mb-4" text type="error">
                            <span
                                class="d-block font-bold leading-relaxed"
                                v-html="$t('AdminLeadEdit.ngoThanks.title')"
                            />
                            <span
                                v-html="
                                    $t('AdminLeadEdit.ngoThanks.false', {
                                        btn_label_text: $t(
                                            'Affiliate.ngoThanks.general.acceptLabel'
                                        ),
                                    })
                                "
                            />
                        </v-alert>
                    </template>

                    <v-alert v-if="ngoInfo" class="mb-8" text type="success">
                        <span
                            class="d-block font-bold leading-relaxed"
                            v-html="$t('AdminLeadEdit.ngoInfo.title')"
                        />
                        <span
                            v-html="
                                $t('AdminLeadEdit.ngoInfo.true', {
                                    btn_label_text: $t('Affiliate.ngoInfo.general.acceptLabel'),
                                })
                            "
                        />
                    </v-alert>
                    <v-alert v-else class="mb-8" text type="error">
                        <span
                            class="d-block font-bold leading-relaxed"
                            v-html="$t('AdminLeadEdit.ngoInfo.title')"
                        />
                        <span
                            v-html="
                                $t('AdminLeadEdit.ngoInfo.false', {
                                    btn_label_text: $t('Affiliate.ngoInfo.general.acceptLabel'),
                                })
                            "
                        />
                    </v-alert>
                </template>

                <template v-if="item.last_name">
                    <v-row class="pb-3">
                        <v-col :md="hasSalutation ? 4 : 6" cols="12">
                            <v-text-field
                                :label="$t('OneWord.firstName')"
                                :model-value="item.first_name"
                                density="compact"
                                hide-details
                                readonly
                            />
                        </v-col>
                        <v-col :md="hasSalutation ? 4 : 6" cols="12">
                            <v-text-field
                                :label="$t('OneWord.lastName')"
                                :model-value="item.last_name"
                                density="compact"
                                hide-details
                                readonly
                            />
                        </v-col>
                        <v-col v-if="hasSalutation" cols="12" md="4">
                            <v-text-field
                                :label="$t('OneWord.salutation.label')"
                                :model-value="salutation(item.salutation)"
                                density="compact"
                                hide-details
                                readonly
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                :label="$t('OneWord.address')"
                                :model-value="item.address"
                                density="compact"
                                hide-details
                                readonly
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                :label="$t('OneWord.zip')"
                                :model-value="item.zip"
                                density="compact"
                                hide-details
                                readonly
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                :label="$t('OneWord.place')"
                                :model-value="item.place"
                                density="compact"
                                hide-details
                                readonly
                            />
                        </v-col>
                        <v-col :md="isSwitzerland ? 6 : 12" cols="12">
                            <v-text-field
                                :label="$t('OneWord.birthday')"
                                :model-value="$filters.date(item.birthday)"
                                density="compact"
                                hide-details
                                readonly
                            />
                        </v-col>
                        <v-col v-if="isSwitzerland" cols="12" md="6">
                            <v-text-field
                                :label="$t('OneWord.hometown')"
                                :model-value="item.hometown"
                                density="compact"
                                hide-details
                                readonly
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                :label="$t('OneWord.email')"
                                :model-value="item.email"
                                density="compact"
                                hide-details
                                readonly
                                type="email"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                :label="$t('OneWord.phone')"
                                :model-value="item.phone"
                                density="compact"
                                hide-details
                                readonly
                                type="tel"
                            />
                        </v-col>
                    </v-row>
                </template>
                <template v-else-if="item.email">
                    <v-row>
                        <v-col>
                            <v-text-field
                                :label="$t('OneWord.email')"
                                :model-value="item.email"
                                density="compact"
                                hide-details
                                readonly
                                type="email"
                            />
                        </v-col>
                    </v-row>
                </template>
                <template v-if="item.deletion_scheduled_at">
                    <v-row>
                        <v-col>
                            <v-alert
                                v-if="item.deletion_scheduled_at"
                                class="mb-8"
                                text
                                type="info"
                            >
                                <span v-if="item.anonymized">{{
                                    $t("AdminLeadEdit.userDeleted")
                                }}</span>
                                <span v-else>{{
                                    $t("AdminLeadEdit.userDeletionScheduled")
                                }}</span>
                                {{ $filters.date(item.deletion_scheduled_at) }}.
                            </v-alert>
                        </v-col>
                    </v-row>
                </template>
                <template
                    v-if="
                        item.lead_type === 'consulting' &&
                            item.consulting_contact_ngo_info_text
                    "
                >
                    <v-row>
                        <v-col>
                            <v-textarea
                                :label="$t('AdminLead.consultingContactNgoInfoText')"
                                :model-value="item.consulting_contact_ngo_info_text"
                                :rows="1"
                                auto-grow
                                hide-details
                                readonly
                            />
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
        </v-card>

        <v-card v-if="item.geo_location" class="mb-4">
            <v-card-title>
                {{ $t("AdminLeadEdit.creationTitle") }}
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" lg="6">
                        <v-text-field
                            :hint="$t('AdminLeadEdit.locationHint')"
                            :label="$t('AdminLeadEdit.location')"
                            :model-value="location"
                            persistent-hint
                        />
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-text-field
                            :label="$t('OneWord.createdAt')"
                            :model-value="$filters.dateTime(item.created_at)"
                            hide-details
                            readonly
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <admin-lead-details :item="item" />

        <v-expansion-panels :disabled="!isNgoThanks(item)" class="mb-4" static>
            <v-expansion-panel class="pa-0">
                <v-expansion-panel-title class="px-4">
                    <v-card-title class="pa-0">
                        {{ $t("AdminTestatorEdit.title") }}
                    </v-card-title>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <v-form @submit.prevent="save">
                        <v-card class="pa-0" flat>
                            <v-card-text class="pa-0">
                                <div class="mb-4">
                                    {{ $t("AdminTestatorEdit.infoText") }}
                                </div>
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <v-checkbox
                                            v-model="item.ngo_is_new_contact"
                                            :false-value="false"
                                            :indeterminate="item.ngo_is_new_contact === null"
                                            :label="$t('AdminTestatorEdit.ngoIsNewContact')"
                                            :true-value="true"
                                        />
                                        <v-checkbox
                                            v-model="item.ngo_is_in_crm"
                                            :false-value="false"
                                            :indeterminate="item.ngo_is_in_crm === null"
                                            :label="$t('AdminTestatorEdit.ngoIsInCrm')"
                                            :true-value="true"
                                        />
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                        <v-textarea
                                            v-model="item.ngo_info_text"
                                            :hint="$t('AdminTestatorEdit.ngoInfoTextHint')"
                                            :label="$t('AdminTestatorEdit.ngoInfoText')"
                                            persistent-hint
                                        />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn :loading="loading" type="submit">
                                    {{ $t("OneWord.save") }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels v-if="item.activities?.length > 0" class="mb-4" static>
            <v-expansion-panel class="pa-0">
                <v-expansion-panel-title class="px-4">
                    <v-card-title class="pa-0">
                        Änderungen
                    </v-card-title>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <v-data-table
                        :headers="activityHeaders"
                        :items="item.activities"
                        density="compact"
                        disable-pagination
                        hide-default-footer
                        hide-default-header
                        items-per-page="-1"
                    />
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels v-if="item.versions?.length" static>
            <v-expansion-panel class="pa-0">
                <v-expansion-panel-title class="px-4">
                    <v-card-title class="pa-0">
                        {{ $t('AdminLeadEdit.otherVersions') }} ({{ item.versions?.length }})
                    </v-card-title>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <v-data-table
                        :headers="versionHeaders"
                        :items="sortedVersions"
                        density="compact"
                        disable-pagination
                        hide-default-footer
                        items-per-page="-1"
                    >
                        <template #item="{ item }">
                            <tr class="cursor-pointer" @click="onRowClick(item)">
                                <td>
                                    <template v-if="item.lead_type === 'will' && item.is_favored !== null">
                                        <v-icon v-if="item.is_favored" color="green">mdi-check-circle</v-icon>
                                        <v-icon v-else color="red">mdi-minus-circle-outline</v-icon>
                                    </template>
                                    <v-tooltip v-else location="bottom">
                                        <template #activator="{ props }">
                                            <span class="text-caption" v-bind="props">{{ $t('OneWord.notAvailableIcon') }}</span>
                                        </template>
                                        <span>{{ $t('OneWord.notAvailableToolTip') }}</span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <span v-if="item.folder_own">{{ $t('AdminLeads.table.folder.user') }}</span>
                                    <v-tooltip v-else>
                                        <template #activator="{ props: props }">
                                            <v-hover>
                                                <span v-bind="props">{{ $t('AdminLeads.table.folder.others') }}</span>
                                            </v-hover>
                                        </template>
                                        <span>{{ item.folder }}</span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <span v-if="item.anonymized">({{ $t('OneWord.deleted') }})</span>
                                    <span v-else-if="item.first_name">{{ item.first_name }}</span>
                                    <span v-else class="text-gray-500">{{ $t('AdminLeads.table.noValue') }}</span>
                                </td>
                                <td>
                                    <span v-if="item.anonymized">({{ $t('OneWord.deleted') }})</span>
                                    <span v-else-if="item.last_name">{{ item.last_name }}</span>
                                    <span v-else class="text-gray-500">{{ $t('AdminLeads.table.noValue') }}</span>
                                </td>
                                <td>{{ $filters.formatDateTime(item.created_at) }}</td>
                                <td>
                                    <v-icon v-if="item.ngo_thanks_computed === 2" color="blue">mdi-information</v-icon>
                                    <v-icon v-else-if="item.ngo_thanks_computed === 1" color="green">mdi-check-circle</v-icon>
                                    <v-icon v-else-if="item.ngo_thanks_computed === 0" color="red">mdi-minus-circle-outline</v-icon>
                                    <v-tooltip v-else location="bottom">
                                        <template #activator="{ props }">
                                            <span class="text-caption" v-bind="props">{{ $t('OneWord.notAvailableIcon') }}</span>
                                        </template>
                                        <span>{{ $t('OneWord.notAvailableToolTip') }}</span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <v-icon v-if="item.ngo_info" color="green">mdi-check-circle</v-icon>
                                    <v-icon v-else color="red">mdi-minus-circle-outline</v-icon>
                                </td>
                                <td>{{ $t(`AdminLeads.leadSource.items.${item.lead_source}`) }}</td>
                                <td>{{ item.id }}</td>
                                <td>
                                    <v-icon v-if="item.ngo_is_visible" color="green">mdi-check-circle</v-icon>
                                    <v-icon v-else color="red">mdi-minus-circle-outline</v-icon>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import { get, has, isNull } from "lodash";
import { mapGetters } from "vuex";
import { EventBus } from "@/utils/event-bus";
import AdminLeadDetails from "./AdminLeadDetails.vue";
import LeadActionMenu from "@/components/Lead/LeadActionMenu.vue"

export default {
    name: "AdminLeadEdit",
    components: {
        AdminLeadDetails,
        LeadActionMenu,
    },
    data() {
        return {
            loading: false,
            dataLoading: false,

            confirmDialog: false,

            item: {
                other_ngos: [],
                activities: [],
            },
        };
    },
    computed: {
        ...mapGetters("user", {
            user: "user",
        }),
        ...mapGetters("env", ["isSwitzerland"]),
        personCardTitle() {
            if (this.item.lead_type === "will") {
                if (has(this.item, "last_name")) {
                    return this.$t("AdminLeadEdit.personData");
                }

                return this.$t("AdminLeadEdit.userData");
            }

            return this.item.lead_type
                ? this.$t(`AdminLeads.leadType.items.${this.item.lead_type}`)
                : "";
        },
        hasSalutation() {
            return this.item.lead_type !== "consulting";
        },
        ngoThanks() {
            return get(this.item, "ngo_thanks", false);
        },
        ngoInfo() {
            return get(this.item, "ngo_info", false);
        },
        hasNgoThanksExternal() {
            /**
       * Legacy Blue Dot:
       * Implizite zustimmung, wenn Zugriff via externen Affiliate-Link erfolgte
       */
            return !isNull(get(this.item, "ngo_thanks_external"));
        },
        ngoThanksExternal() {
            /**
       * Legacy Blue Dot:
       * Implizite zustimmung, wenn Zugriff via externen Affiliate-Link erfolgte
       */
            return get(this.item, "ngo_thanks_external", false);
        },
        location() {
            return _([
                _.get(this.item, "geo_location.plz"),
                _.get(this.item, "geo_location.place"),
                _.get(this.item, "geo_location.state_name"),
                _.get(this.item, "geo_location.country"),
            ])
                .filter()
                .join(", ");
        },
        activityHeaders() {
            return [
                {title: 'Datum', key: 'created_at', value: item => this.$filters.formatDateTime(item.created_at) },
                {title: 'Log', key: 'description' },
            ];
        },
        versionHeaders() {
            return [
                {title: this.$t('AdminLeadEdit.section.will.isFavouredInfo'), value: 'is_favored', sortable: true},
                {title: this.$t('AdminLeads.table.header.folder'), value: 'folder', sortable: true},
                {title: this.$t('OneWord.firstName'), value: 'first_name', sortable: true},
                {title: this.$t('OneWord.lastName'), value: 'last_name', sortable: true},
                {title: this.$t('OneWord.date'), value: 'created_at', sortable: true},
                {title: this.$t('AdminTestator.ngoThanks'), value: 'ngo_thanks_computed', sortable: true},
                {title: this.$t('AdminTestator.ngoInfo'), value: 'ngo_info', sortable: true},
                {title: this.$t('AdminLeads.source'), value: 'lead_source', sortable: true},
                {title: this.$t('AdminLeads.ID'), value: 'id', sortable: true},
                {title: this.$t('AdminLeads.table.ngoIsVisible'), value: 'ngo_is_visible', sortable: false},
            ];
        },
        sortedVersions() {
            return _.orderBy(this.item.versions, 'created_at', 'desc');
        },
    },
    watch: {
        '$route.params.lead_id': {
            handler: 'load',
        },
    },
    mounted() {
        this.load();
    },
    methods: {
        load() {
            this.dataLoading = true;
            axios
                .get(`/webapi/leads/${this.$route.params.lead_id}`, {params: {with_other_ngos: true}})
                .then((response) => {
                    this.item = get(response, "data.data");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
        salutation(value) {
            switch (value) {
            case "m":
                return this.$t("OneWord.salutation.m");
            case "f":
                return this.$t("OneWord.salutation.f");
            case "d":
                return this.$t("OneWord.salutation.d");
            default:
                return value;
            }
        },
        isNgoThanks(item) {
            return get(item, "ngo_thanks", false);
        },
        save() {
            this.loading = true;
            axios
                .put(`/webapi/leads/${this.item.id}`, this.item)
                .then(() => {
                    EventBus.$emit("success");
                })
                .catch((error) => {
                    EventBus.$emit("error", get(error, "response.data.message"));
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openUrl(url) {
            window.open(url, "_blank");
        },
        updateToggle() {
            this.load();
            EventBus.$emit('success');
        },
        errorToggle(message) {
            EventBus.$emit('error', message);
        },
        onRowClick(item) {
            this.$router.push({
                name: 'admin-lead-edit',
                params: {lead_id: item.id},
            });
        },
    },
};
</script>

<style scoped></style>