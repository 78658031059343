<template>
    <div>
        <h1 class="text-h6 mb-2">
            {{ $t('AdminTools.title') }}
        </h1>

        <v-card :loading="loading" :disabled="loading">
            <v-card-title>
                {{ $t('AdminTools.profileUrl.title') }}
            </v-card-title>
            <v-card-text>
                <v-alert type="info" class="">
                    {{ $t('AdminTools.profileUrl.info') }}
                </v-alert>

                <template v-for="profile in profiles" :key="`field-${profile.id}`">
                    <div class="text-subtitle-1 my-2">
                        {{ $t('OneWord.profil') }}: {{ profile.company_name }}
                    </div>

                    <v-text-field
                        :model-value="profile.url"
                        class="mr2"
                        prepend-inner-icon="mdi-link"
                        readonly
                        single
                        flat
                        variant="solo-filled"
                    >
                        <template #append>
                            <v-btn
                                icon
                                variant="flat"
                                @click="copyToClipboard(profile.url)"
                            >
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                </template>
            </v-card-text>
        </v-card>

        <v-card :loading="loading" :disabled="loading" class="mt-4">
            <v-card-title>
                {{ $t('AdminTools.backlinks.title') }}
            </v-card-title>
            <v-card-text class="text-medium-emphasis">
                <v-alert type="info" class="mb-4">
                    {{ $t('AdminTools.backlinks.info') }}
                </v-alert>

                <v-alert type="warning" class="mb-4">
                    <span v-html="$t('AdminTools.backlinks.info2')" />
                </v-alert>

                <v-btn-toggle
                    v-model="langForLinks"
                    mandatory
                    density="compact"
                    variant="outlined"
                    divided
                    color="accent"
                >
                    <v-btn
                        v-for="lang in languages"
                        :key="`lang-btn-${lang.locale}`"
                        :value="lang.locale"
                        :data-cy="'btn-backlink-lang-' + lang.locale.substring(0, 2)"
                    >
                        <template v-if="$vuetify.display.smAndDown">
                            <span class="uppercase">{{ lang.locale.substring(0, 2) }}</span>
                        </template>
                        <template v-else>
                            {{ lang.label }}
                        </template>
                    </v-btn>
                </v-btn-toggle>

                <v-dialog v-model="qr.show" width="500">
                    <v-card>
                        <v-card-title class="text-h5 bg-grey-lighten-2">{{ qr.headline }}</v-card-title>
                        <a :href="qr.backlink" target="_blank"><img
                            id="qrcode"
                            :src="qr.image"
                            :alt="qr.filename"
                            width="800"
                            height="800"
                        ></a>
                        <v-divider />
                        <div ref="dialog" class="shortlink flex">
                            <v-text-field
                                :model-value="qr.shortlink"
                                variant="solo-filled"
                                flat
                                readonly
                                single
                                prepend-inner-icon="mdi-link"
                                class="mr2"
                            >
                                <template #append>
                                    <v-btn
                                        icon
                                        variant="flat"
                                        @click="copyToClipboard(qr.shortlink, $refs.dialog)"
                                    >
                                        <v-icon>mdi-content-copy</v-icon>
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </div>
                        <v-divider />
                        <v-card-actions>
                            <v-btn
                                size="small"
                                color="primary"
                                @click="downloadQRCode()"
                            >
                                <v-icon start>mdi-download</v-icon>
                                {{ $t('OneWord.download') }}
                            </v-btn>
                            <v-spacer />
                            <v-btn
                                color="primary"
                                variant="text"
                                @click="qr.show = false"
                            >
                                {{ $t('OneWord.close') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <div v-for="profile in profiles" :key="`profile-${profile.id}`">
                    <div class="text-subtitle-1 my-2">
                        {{ $t('OneWord.profil') }}: {{ profile.company_name }}
                    </div>

                    <template v-if="$hasFeature($Features.Testament)">
                        <div>{{ $t('AdminTools.backlinks.testament') }}</div>

                        <div class="flex">
                            <v-text-field
                                :model-value="profile.backlinks.testament[localeCode]"
                                class="mr2"
                                flat
                                prepend-inner-icon="mdi-link"
                                readonly
                                single
                                variant="solo-filled"
                            />
                            <v-btn
                                icon
                                variant="flat"
                                class="ma-2 mr-0 inline-block"
                                @click="copyToClipboard(profile.backlinks.testament[localeCode])"
                            >
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                variant="flat"
                                class="ma-2 mr-0 inline-block"
                                @click="showQRCode(profile, 'testament', $t('AdminTools.backlinks.testament'), profile.slug)"
                            >
                                <v-icon>mdi-qrcode</v-icon>
                            </v-btn>
                        </div>
                    </template>

                    <template v-if="$hasFeature($Features.PatientDecree)">
                        <div>{{ $t('AdminTools.backlinks.patientDecree') }}</div>

                        <div class="flex">
                            <v-text-field
                                :model-value="profile.backlinks.patientDecree[localeCode]"
                                variant="solo-filled"
                                flat
                                readonly
                                single
                                prepend-inner-icon="mdi-link"
                                class="mr2"
                            />
                            <v-btn
                                icon
                                variant="flat"
                                class="ma-2 mr-0 inline-block"
                                @click="copyToClipboard(profile.backlinks.patientDecree[localeCode])"
                            >
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                variant="flat"
                                class="ma-2 mr-0 inline-block"
                                @click="showQRCode(profile, 'patientDecree', $t('AdminTools.backlinks.patientDecree'), profile.slug)"
                            >
                                <v-icon>mdi-qrcode</v-icon>
                            </v-btn>
                        </div>
                    </template>

                    <template v-if="$hasFeature($Features.AdvanceCareDirective)">
                        <div>{{ $t('AdminTools.backlinks.advanceCareDirective') }}</div>

                        <div class="flex">
                            <v-text-field
                                :model-value="profile.backlinks.advanceCareDirective[localeCode]"
                                class="mr2"
                                flat
                                prepend-inner-icon="mdi-link"
                                readonly
                                single
                                variant="solo-filled"
                            />
                            <v-btn
                                icon
                                variant="flat"
                                class="ma-2 mr-0 inline-block"
                                @click="copyToClipboard(profile.backlinks.advanceCareDirective[localeCode])"
                            >
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                variant="flat"
                                class="ma-2 mr-0 inline-block"
                                @click="showQRCode(profile, 'advanceCareDirective', $t('AdminTools.backlinks.advanceCareDirective'), profile.slug)"
                            >
                                <v-icon>mdi-qrcode</v-icon>
                            </v-btn>
                        </div>
                    </template>

                    <div>{{ $t('AdminTools.backlinks.login') }}</div>

                    <div class="flex">
                        <v-text-field
                            :model-value="profile.backlinks.login[localeCode]"
                            variant="solo-filled"
                            flat
                            readonly
                            single
                            prepend-inner-icon="mdi-link"
                            class="mr2"
                        />
                        <v-btn
                            icon
                            variant="flat"
                            class="ma-2 mr-0 inline-block"
                            @click="copyToClipboard(profile.backlinks.login[localeCode])"
                        >
                            <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            variant="flat"
                            class="ma-2 mr-0 inline-block"
                            @click="showQRCode(profile, 'login', $t('AdminTools.backlinks.login'), profile.slug)"
                        >
                            <v-icon>mdi-qrcode</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>

        <admin-webinars 
            v-if="ngoProfile && isSwitzerland"
            :profile="ngoProfile"
            :languages="languages"
            :locale="locale"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {EventBus} from '@/utils/event-bus';
import {usei18n} from '@/plugins/i18n';
import {Features as $Features} from "@/plugins/features";
import AdminWebinars from './AdminWebinars.vue';

export default {
    name: 'AdminTools',
    components: {
        AdminWebinars
    },
    data() {
        return {
            loading: false,
            profiles: [],
            langForLinks: 'de-CH', // default locale
            qr: {
                show: false,
                headline: null,
                image: null,
                backlink: null,
                shortlink: null,
            },
        };
    },
    computed: {
        $Features() {
            return $Features
        },
        ...mapGetters('user', [
            'user',
        ]),
        ...mapGetters('env', [
            'isSwitzerland',
        ]),
        downloadHeaders() {
            return [
                {value: 'name'},
                {value: 'info'},
                {value: 'status'},
                {value: 'download', align: 'end'},
            ];
        },
        languages() {
            return [
                {locale: 'de-CH', label: this.$t('OneWord.lang.german')},
                {locale: 'en-GB', label: this.$t('OneWord.lang.english')},
                {locale: 'fr-CH', label: this.$t('OneWord.lang.french')},
                {locale: 'it-CH', label: this.$t('OneWord.lang.italian')},
            ];
        },
        locale() {
            switch (usei18n().locale) {
            case 'en':
                return 'en-GB';
            case 'fr':
                return 'fr-CH';
            case 'it':
                return 'it-CH';
            default:
                return 'de-CH';
            }
        },
        localeCode() {
            return (this.langForLinks || '').substring(0, 2) ?? 'de';
        },
        ngoProfile() {
            return _.find(this.profiles, {is_ngo: true}) || null;
        },
    },
    mounted() {
        EventBus.$on('locale-changed', this.load);
        this.langForLinks = this.locale;
        this.load();
    },
    beforeUnmount() {
        EventBus.$off('locale-changed', this.load);
    },
    methods: {
        ...mapActions('user', {
            loadUser: 'load',
        }),
        load() {
            this.loading = true;
            window.axios.get(`/webapi/accounts/${this.user.account_id}/profiles`)
                .then(response => {
                    this.profiles = response.data.data;
                }).finally(() => {
                    this.loading = false;
                });
        },
        copyToClipboard(value, container) {
            this.$copyText(value, container).then(() => {
                EventBus.$emit('success', this.$t('Clipboard.success'));
            }, () => {
                EventBus.$emit('error', this.$t('Clipboard.failure'));
            });
        },

        /**
         * Get QR code and show in modal
         *
         * Will get the base64 encoded QR code for display
         * as well as the filename (slug + headline + lang).
         */
        showQRCode(profile, tool, headline, slug) {
            let backlink = profile.backlinks[tool][this.localeCode];
            let shortlink = profile.shortlinks[tool][this.localeCode];
            axios.get('/webapi/qr-code', {
                params: {
                    url: backlink,
                    filename: [
                        slug,
                        headline,
                        this.langForLinks.substring(0, 2),
                    ].join('-'),
                }
            }).then(response => {
                // set the params used in the dialog
                this.qr.backlink = backlink;
                this.qr.shortlink = shortlink;
                this.qr.headline = headline;
                this.qr.image = response.data.image;
                this.qr.filename = response.data.filename;
                this.qr.show = true;
            });
        },

        /**
         * Download the QR code currently displayed in the dialog.
         */
        downloadQRCode() {
            const params = new URLSearchParams({
                url: this.qr.backlink,
                filename: this.qr.filename,
            });

            window.location.href = '/webapi/qr-code/download?' + params.toString();
        },
        download(item) {
            window.location.href = item.url;
        },
        addLangToUrl(url, isShortlink) {
            if (this.langForLinks !== 'de-CH') {
                let lang = this.langForLinks.substring(0, 2);
                if (isShortlink) {
                    return `${url}/${lang}`;
                }
                return `${url}?lang=${lang}`;
            }
            return url;
        },
    }
};
</script>

<style scoped lang="scss">
.shortlink {
  padding: 20px 30px 0;
}

.v-divider {
    &.v-theme--light {
        opacity: 1;
    }
}
</style>
