<template>
    <v-sheet
        v-if="hasProfile"
        :class="{'pa-10': $vuetify.display.lgAndUp}"
        class="pa-6 mb-4 mt-2 rounded-md text-gray-600 lg:rounded-xl"
        data-cy="affiliate-contact"
    >
        <div v-if="hasProfile && !isWhiteLabel" class="mb-6 text-sm" data-cy="affiliate-possible-by">{{ $t('TestamentToolPopover.madePossibleBy') }}</div>
        
        <v-row>
            <v-col
                cols="12"
                lg="6"
                align-self="center"
            >
                <v-img
                    v-if="profile.logo"
                    :src="profileImageUrl"
                    max-height="6rem"
                    max-width="18rem"
                    class="mx-auto"
                />
            </v-col>

            <v-col cols="12" lg="6" align-self="center">
                <div v-if="hasProfileImage" class="mx-auto whitespace-nowrap">
                    <div class="mr-5 hidden sm:inline-block">
                        <div
                            :style="{ backgroundImage: `url('${profile.person_image.url}')` }"
                            class="mx-auto size-20 rounded-full border border-solid border-gray-300 bg-cover bg-center bg-no-repeat"
                        />
                    </div>

                    <div class="inline-block pt-1 text-left align-top text-sm text-black">
                        <div class="text-lg font-bold">{{ profile.first_name }} {{ profile.last_name }}</div>
                        <div>{{ profile.email }}</div>
                        <div>{{ profile.phone_formatted }}</div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'AffiliateHeader',

    computed: {
        ...mapGetters('affiliateProfile', [
            'profile',
            'hasProfile',
        ]),

        ...mapGetters('env', [
            'isWhiteLabel',
        ]),

        profileImageUrl() {
            return _.get(this.profile, 'logo.url');
        },

        hasProfileImage() {
            return !_.isEmpty(_.get(this.profile, 'person_image.url'));
        },
    },
};
</script>

<style lang="scss" scoped>

</style>