<template>
    <div>
        <div v-if="folderTitle">
            <small>{{ $t('ToolFolder.prefix') }}</small>
            <v-chip
                class="ma-2"
                closable
                :data-cy="'chip-folder-' + (folderId ? 'other' : 'own')"
                @click:close="resetFolder"
            >
                {{ folderTitle }}
            </v-chip>
            <v-tooltip max-width="280" location="bottom">
                <template #activator="{ props }">
                    <v-icon
                        v-bind="props"
                        color="grey-lighten-1"
                        icon="mdi-help-circle-outline"
                        size="small"
                    />
                </template>
                <span>{{ $t('ToolFolder.hint') }}</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tool: {
            type: String,
            required: true,
        },
    },
    computed: {
        folderId() {
            return this.$store.getters[this.tool + '/folderId'];
        },

        folderTitle() {
            let folder = _.find(this.$store.state.user.folders, {id: this.folderId});

            if (folder) {
                return folder.title;
            }

            return this.folderId === 0 ? this.$t('OneWord.myDocuments') : null;
        },
    },
    mounted() {
        if (this.$route.query.folder) {
            this.setFolderId(this.$route.query.folder);
        }
    },
    methods: {
        setFolderId(id) {
            this.$store.dispatch(this.tool + '/setFolderId', id);
        },

        resetFolder() {
            this.setFolderId(null);
        },
    }
};
</script>

<style lang="scss" scoped>

</style>
