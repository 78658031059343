<template>
    <v-row class="document-status p-4">
        <v-col 
            :class="getCursor(isWritten)"
            :data-cy="'status-written-' + (isWritten ? 'active' : 'inactive')"
            @click="$emit('mark', 'written')"
            @mouseover="hovered = 1"
            @mouseout="hovered = null"
        >
            <v-btn
                class="ma-2"
                :class="getCursor(isWritten)" 
                :color="hovered || isWritten ? 'green' : 'grey-lighten-1'"
                :icon="hovered >= 1 || isWritten ? 'mdi-check' : 'mdi-close'"
                size="x-small"
                variant="flat"
                max-height="24"
                max-width="24"
            />
            <small class="block" :class="hovered || isWritten ? 'text-green' : 'text-gray-500'">
                {{ document.type === 'patient_decree' ? $t('MyDocumentItem.markAsPrinted') : $t('MyDocumentItem.markAsWritten') }}
            </small>
        </v-col>
        <v-col 
            :class="[getCursor(isSigned), !isWritten ? 'is-disabled' : null]"
            :data-cy="'status-signed-' + (isSigned ? 'active' : 'inactive')"
            @click="$emit('mark', 'signed')"
            @mouseover="hovered = 2"
            @mouseout="hovered = null"
        >
            <v-btn
                class="ma-2"
                :class="getCursor(isSigned)" 
                :color="hovered >= 2 || isSigned ? 'green' : 'grey-lighten-1'"
                :icon="hovered >= 2 || isSigned ? 'mdi-check' : 'mdi-close'"
                size="x-small"
                variant="flat"
                max-height="24"
                max-width="24"
            />
            <small class="block" :class="hovered >= 2 || isSigned ? 'text-green' : 'text-gray-500'">{{ $t('MyDocumentItem.markAsSigned') }}</small>
        </v-col>
        <v-col
            :class="getCursor(isArchived)" 
            :data-cy="'status-archived-' + (isArchived ? 'active' : 'inactive')"
            @click="$emit('mark', 'archived')"
            @mouseover="hovered = 3"
            @mouseout="hovered = null"
        >
            <v-btn
                class="ma-2"
                :class="getCursor(isArchived)" 
                :color="hovered === 3 || isArchived ? 'green' : 'grey-lighten-1'"
                :icon="hovered === 3 || isArchived ? 'mdi-check' : 'mdi-close'"
                size="x-small"
                variant="flat"
                max-height="24"
                max-width="24"
            />
            <small class="block" :class="hovered === 3 || isArchived ? 'text-green' : 'text-gray-500'">{{ $t('MyDocumentItem.markAsArchived') }}</small>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'DocumentStatus',
    props: {
        document: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            hovered: null,
        }
    },
    computed: {
        isWritten() {
            return this.document?.written_at;
        },
        isSigned() {
            return this.document?.signed_at;
        },
        isArchived() {
            return this.document?.archived_at;
        },
    },
    methods: {
        getCursor(marked) {
            return marked ? 'cursor-default' : 'cursor-pointer';
        }
    }
};
</script>

<style lang="scss" scoped>
.v-btn :deep(.v-icon) {
    color: #fff;
}
</style>
