<template>
    <div>
        <vee-field
            v-slot="{ componentField, errors }"
            v-model="profile.profile_title"
            :label="$t('ProfileForm.profileTitle')"
            name="profile_title"
            rules="required|max:255"
        >
            <v-text-field
                v-bind="componentField"
                :counter="255"
                :error-messages="errors"
                :label="$t('ProfileForm.profileTitle')"
            />
        </vee-field>

        <vee-field
            v-slot="{ componentField, errors }"
            v-model="editorContent"
            :label="$t('ProfileForm.profileDescription')"
            name="description"
            rules="required"
        >
            <div>{{ $t('ProfileForm.profileDescription') }}</div>
            <v-card variant="outlined" :color="errors.length > 0 ? 'error' : 'grey-lighten-2'" class="mt-2">
                <VuetifyTiptap
                    data-cy="profile-description"
                    :model-value="componentField.modelValue"
                    :extensions="extensions"
                    @update:model-value="componentField['onUpdate:modelValue']"
                >
                    <template #bottom><div /></template>
                </VuetifyTiptap>
            </v-card>
            <div class="text-red mt-1">{{ errors[0] }}&nbsp;</div>
        </vee-field>

        <vee-field 
            v-slot="{ componentField, errors }" 
            v-model="keyFiguresContent"
            :label="$t('ProfileForm.profileKeyFigures')" 
            name="key_figures" 
            rules="required"
        >
            <div class="mt-2">{{ $t('ProfileForm.profileKeyFigures') }}</div>
            <v-card variant="outlined" :color="errors.length > 0 ? 'error' : 'grey-lighten-2'" class="mt-2">
                <VuetifyTiptap
                    data-cy="profile-key-figures"
                    :model-value="componentField.modelValue"
                    :extensions="extensions"
                    @update:model-value="componentField['onUpdate:modelValue']"
                >
                    <template #bottom><div /></template>
                </VuetifyTiptap>
            </v-card>
            <div class="text-red mt-1">{{ errors[0] }}&nbsp;</div>
        </vee-field>

        <vee-field 
            v-slot="{ errors }"
            v-model="profile.service_categories"
            :label="$t('ProfileForm.profileCategories')" 
            :rules="profileCategories.length > 0 ? 'required' : null"
            name="service_categories"
        >
            <v-autocomplete
                v-model="profile.service_categories"
                :disabled="profileCategories.length === 0"
                :error-messages="errors"
                :hint="$t('ProfileForm.profileCategories')"
                :items="profileCategories"
                :label="$t('ProfileForm.profileCategories')"
                :loading="profileCategoriesLoading"
                chips
                data-cy="service-categories"
                closable-chips
                item-title="name"
                item-value="id"
                multiple
                persistent-hint
                return-object
                @update:model-value="limitSelection($event, 3)"
            >
                <template #chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :prepend-avatar="item.raw.avatar"
                        :text="profileCategoriesItem(item)"
                    />
                </template>
            </v-autocomplete>
        </vee-field>

        <vee-field 
            v-slot="{ errors }"
            v-model="profile.services"
            :label="$t('ProfileForm.profileServices')" 
            :rules="profileServices.length > 0 ? 'required' : null"
            name="services"
        >
            <v-autocomplete
                v-model="profile.services"
                :disabled="profileServices.length === 0"
                :error-messages="errors"
                :hint="$t('ProfileForm.profileServicesHint')"
                :items="profileServices"
                :label="$t('ProfileForm.profileServices')"
                :loading="profileServicesLoading"
                chips
                class="mt-4"
                data-cy="services"
                closable-chips
                item-title="name"
                item-value="id"
                multiple
                return-object
                persistent-hint
            >
                <template #chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :prepend-avatar="item.raw.avatar"
                        :text="profileServicesItem(item)"
                    />
                </template>
            </v-autocomplete>
        </vee-field>
    </div>
</template>

<script>
import {EventBus} from '@/utils/event-bus';
import {Field as VeeField} from "vee-validate";
import {Bold, BulletList, Heading, History, Italic, Link, OrderedList, Strike, VuetifyTiptap, Underline} from 'vuetify-pro-tiptap';

export default {
    name: 'ProfileDescriptionForm',
    components: {VeeField, VuetifyTiptap},
    props: {
        modelValue: {
            type: Object,
            default() {
                return {
                    profile_title: null,
                    description: null,
                };
            },
        },
    },
    data() {
        return {
            extensions: [
                Heading.configure({
                    levels: [1, 2, 3],
                }),
                BulletList,
                OrderedList,
                Link,
                Bold,
                Italic,
                Strike,
                Underline,
                History,
            ],
            profile: {},
            profileServicesLoading: false,
            profileCategoriesLoading: false,
            profileCategories: [],
            profileServices: [],
        };
    },
    computed: {
        editorContent: {
            get() {
                return this.profile.description;
            },
            set(value) {
                if (value === '<p></p>' || value === '' || value === null) {
                    this.profile.description = '';
                }
                else {
                    this.profile.description = value;
                }
            },
        },
        keyFiguresContent: {
            get() {
                return this.profile.key_figures;
            },
            set(value) {
                if (value === '<p></p>' || value === '' || value === null) {
                    this.profile.key_figures = '';
                }
                else {
                    this.profile.key_figures = value;
                }
            },
        },
    },
    watch: {
        modelValue(value) {
            if (value) {
                this.profile = value;
                this.load();
            }
        },
        profile: {
            deep: true,
            handler(value) {
                this.$emit('update:modelValue', value);
            },
        },
    },
    mounted() {
        EventBus.$on('locale-changed', this.load);
        this.load();
    },
    beforeUnmount() {
        EventBus.$off('locale-changed', this.load);
    },
    methods: {
        load() {
            let partnerTypeIds = _.map(_.get(this.profile, 'partner_types', []), 'id');

            if (_.isEmpty(partnerTypeIds)) {
                return;
            }

            axios.post('/webapi/services', {
                partner_types: partnerTypeIds,
            }).then((response) => {
                this.profileServices = response.data.data;
            }).finally(() => {
                this.profileServicesLoading = false;
            });

            axios.post('/webapi/service-categories', {
                partner_types: partnerTypeIds,
            }).then(response => {
                this.profileCategories = response.data.data;
            }).finally(() => {
                this.profileCategoriesLoading = false;
            });
        },

        limitSelection(e, limit) {
            if ( ! limit) limit = 3;
            if (e.length > limit) {
                EventBus.$emit('info', this.$t('ProfileForm.profileCategoriesMaxInfo'));
                e.pop();
            }
        },
        profileCategoriesItem(item) {
            return _.get(_.find(this.profileCategories, {id: item.value}), 'name');
        },
        profileServicesItem(item) {
            return _.get(_.find(this.profileServices, {id: item.value}), 'name');
        }
    },
};
</script>