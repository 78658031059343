import router from '@/router';

const initialState = {
    step: 0,
    visitedSteps: [0],
    completed: false,
    agreementAccepted: false,
    ngoThanksSubmitted: false,

    patientDecree: {},
};

const state = _.cloneDeep({...initialState, ...{
    // Define here all properties which should
    // not be reset by the resetState method:
    folderId: null,
}});

const getters = {
    patientDecree: state => state.patientDecree,

    // general tool getters
    step: state => state.step,
    visitedSteps: state => state.visitedSteps,
    completed: state => state.completed,
    folderId: state => state.folderId,
    ngoThanksSubmitted: state => state.ngoThanksSubmitted,
    agreementAccepted: state => state.agreementAccepted,

    // specific patient decree getters
    authorizedRepresentative: state => _.get(state.patientDecree, 'authorized_representative'),
    replacementPerson: state => _.get(state.patientDecree, 'replacement_person'),
    hasAuthorizedRepresentative: state => {
        if (_.has(state.patientDecree, 'authorized_representative.active')) {
            return _.get(state.patientDecree, 'authorized_representative.active');
        }

        // Legacy
        return !_.isEmpty(_.get(state.patientDecree, 'authorized_representative.last_name'));
    },
    hasReplacementPerson: state => {
        if (_.has(state.patientDecree, 'replacement_person.active')) {
            return _.get(state.patientDecree, 'replacement_person.active');
        }

        // Legacy
        return !_.isEmpty(_.get(state.patientDecree, 'replacement_person.last_name'))
    },
};

const actions = {
    set: ({commit}, value) => commit('set', value),
    patch: ({commit}, value) => commit('patch', value),
    setStep: ({commit}, value) => commit('step', value),
    setCompleted: ({commit}) => commit('setCompleted'),
    setNgoThanksSubmitted: ({commit}) => commit('setNgoThanksSubmitted'),
    setFolderId: ({commit}, value) => commit('setFolderId', value),
    setAgreementAccepted: ({commit}, value) => commit('setAgreementAccepted', value),
    reset: ({commit}) => commit('resetState'),
    restart: ({commit}) => commit('restartTool'),
};

const mutations = {
    set(state, value) {
        state.patientDecree = value;
    },
    patch(state, value) {
        state.patientDecree = _.merge(state.patientDecree, value);
    },
    step(state, value) {
        state.step = parseInt(value);

        if (state.step !== _.last(state.visitedSteps)) {
            state.visitedSteps.push(state.step);
        }
    },
    setCompleted(state) {
        state.completed = true;
    },
    setFolderId(state, value) {
        state.folderId = isNaN(parseInt(value)) ? null : parseInt(value);
    },
    setNgoThanksSubmitted(state) {
        state.ngoThanksSubmitted = true;
    },
    setAgreementAccepted(state, value) {
        state.agreementAccepted = value;
    },
    resetState(state) {
        Object.assign(state, _.cloneDeep(initialState));
    },
    restartTool(state) {
        mutations.resetState(state);
        router.push({name: 'patient-decree-tool'});
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};