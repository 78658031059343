<template>
    <div data-cy="testament-tool">
        <v-row class="mt-0">
            <v-col cols="12" md="6">
                <h1 class="text-h6 mb-6">
                    {{ $t('TestamentTool.title') }}
                </h1>
            </v-col>

            <v-col cols="12" md="6" class="md:text-right">
                <tool-folder tool="testament" />
            </v-col>
        </v-row>

        <v-sheet class="pa-4" :class="{'elevation-1': $vuetify.display.lgAndUp}">
            <tool-stepper
                :step="step"
                :steps="steps"
            />

            <component :is="stepComponent" />
        </v-sheet>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ToolStepper from '@/components/ToolStepper';
import ToolFolder from '@/components/ToolFolder';
import { defineAsyncComponent } from 'vue';

export default {
    name: 'TestamentTool',

    components: {
        ToolFolder,
        ToolStepper,
        TestamentStep1: defineAsyncComponent(() => import('@/pages/testament/TestamentStep1')),
        TestamentStep2: defineAsyncComponent(() => import('@/pages/testament/TestamentStep2')),
        TestamentStep3: defineAsyncComponent(() => import('@/pages/testament/TestamentStep3')),
        TestamentStep4: defineAsyncComponent(() => import('@/pages/testament/TestamentStep4')),
        TestamentStep5: defineAsyncComponent(() => import('@/pages/testament/TestamentStep5')),
        TestamentStep6: defineAsyncComponent(() => import('@/pages/testament/TestamentStep6')),
        TestamentStep7: defineAsyncComponent(() => import('@/pages/testament/TestamentStep7')),
    },

    beforeRouteEnter(to, from, next) {
        if (to.name === 'testament-edit') {
            axios.get(`/webapi/testaments/${to.params.testament_id}`).then(response => {
                next(vm => {
                    vm.resetTestament();
                    vm.setTestator(_.get(response.data, 'data.testator'));
                    vm.setStep(1);
                });
            }).catch(() => {
                next({name: 'my-documents'});
            });
        } else {
            next();
        }
    },

    data() {
        return {};
    },

    computed: {
        ...mapGetters('testament', [
            'step',
        ]),

        stepComponent() {
            return `TestamentStep${this.step}`;
        },

        steps() {
            return [
                {
                    step: 1,
                    text: this.$t('TestamentTool.stepper.step1'),
                }, {
                    step: 2,
                    text: this.$t('TestamentTool.stepper.step2'),
                }, {
                    step: 3,
                    text: this.$t('TestamentTool.stepper.step3'),
                }, {
                    step: 4,
                    text: this.$t('TestamentTool.stepper.step4'),
                }, {
                    step: 5,
                    text: this.$t('TestamentTool.stepper.step5'),
                }, {
                    step: 6,
                    text: this.$t('TestamentTool.stepper.step6'),
                }, {
                    step: 7,
                    text: this.$t('TestamentTool.stepper.step7'),
                },
            ];
        },
    },

    watch: {
        '$route'(to) {
            if (to.name === 'testament-affiliate') {
                this.loadAffiliate(to.params.profile_slug);
            }
        },

        step(value) {
            this.changeStep(value);
        },
    },

    mounted() {
        let profileSlug = _.get(this.$route.params, 'profile_slug', false);

        if (profileSlug) {
            this.loadAffiliate(profileSlug);
        }
    },

    methods: {
        ...mapActions('testament', {
            setStep: 'setStep',
            setTestator: 'setTestator',
            resetTestament: 'reset',
        }),

        ...mapActions('affiliateProfile', {
            loadAffiliate: 'load',
        }),

        changeStep() {
            window.scrollTo(0, 0);
        },
    },
};
</script>

<style scoped>

</style>
