<template>
    <v-card v-if="item" class="mb-4">
        <v-card-title>
            {{ $t('AdminLeadEdit.section.will.title') }}
        </v-card-title>

        <v-col
            v-if="item.lead_type === 'will' || item.lead_type === 'consulting'"
            class="lead-details--row mx-1"
            cols="12"
        >
            <div class="underline">{{ $t('AdminLeadEdit.section.will.consideration') }}</div>
            <div class="mb-2 text-sm text-gray-600">{{ $t('AdminLeadEdit.section.will.considerationDescription') }}</div>
            <template v-if="item.is_favored === true">
                <v-chip data-cy="lead-source-consideration-favoured" class="my-1 mr-2 bg-green-200 text-green-700">{{ $t('AdminLeadEdit.section.will.isFavoured') }}</v-chip>
            </template>
            <template v-if="item.is_favored === false && item.is_removed === true">
                <v-chip data-cy="lead-source-consideration-removed" class="my-1 mr-2 bg-red-100 text-red-500">{{ $t('AdminLeadEdit.section.will.isRemoved') }}</v-chip>
            </template>
            <template v-if="item.is_favored === false && item.is_removed === false">
                <v-chip data-cy="lead-source-consideration-zero" class="my-1 mr-2 bg-red-100 text-red-500">{{ $t('AdminLeadEdit.section.will.zeroPercent') }}</v-chip>
            </template>
            <template v-if="item.is_favored === null && item.is_removed === null">
                <v-chip data-cy="lead-source-consideration-unknown" class="my-1 mr-2 bg-gray-100 text-gray-400">{{ $t('OneWord.notAvailableToolTip') }}</v-chip>
            </template>
        </v-col>

        <v-col 
            v-if="item.other_ngos.length > 0"
            cols="12"
            class="lead-details--row mx-1"
        >
            <div class="underline">{{ $t('AdminLeadEdit.section.will.otherNgos') }}</div>
            <div class="mb-2 text-sm text-gray-600">{{ $t('AdminLeadEdit.section.will.otherNgosDescription') }}</div>
            <v-chip
                v-for="ngo in item.other_ngos"
                :key="ngo"
                data-cy="lead-source-other-ngo" 
                class="my-1 mr-2"
            >
                {{ ngo }}
            </v-chip>
        </v-col>

        <v-col 
            v-if="item.lead_source"
            cols="12" 
            class="lead-details--row mx-1"
        >
            <div class="underline">{{ $t('AdminLeadEdit.section.will.sourceTitle') }}</div>
            <div class="mb-2 text-sm text-gray-600">{{ $t('AdminLeadEdit.section.will.sourceDescription') }}</div>
            <v-chip
                class="lead-source my-1 mr-2"
                :data-cy="'lead-source-' + item.lead_source"
            >
                {{ $t('AdminLeadEdit.section.will.source.' + item.lead_source) }}
            </v-chip>
        </v-col>

        <v-col 
            cols="12" 
            class="lead-details--row mx-1"
        >
            <div class="underline">{{ $t('AdminLeadEdit.section.version.title') }}</div>
            <div class="mb-2 text-sm text-gray-600">{{ $t('AdminLeadEdit.section.version.description') }}</div>
            <v-chip
                class="my-1 mr-2"
                :data-cy="'lead-version-' + item.is_latest_version"
                :color="item.is_latest_version ? 'indigo' : 'teal'"
            >
                {{ item.is_latest_version ? $t('AdminLeads.latestVersion') : $t('AdminLeads.olderVersion') }}
            </v-chip>
        </v-col>
    </v-card>
</template>

<script>
export default {
    name: 'AdminLeadDetails',
    props: {
        item: Object,
    },
}
</script>

<style scoped>
.lead-source {
    color: #fff;
    background: var(--da-primary) !important;
}

.lead-details--row {
    columns: 2;
}

.lead-details--row + .lead-details--row {
    border-top: 1px dashed #d3d3d3;
}
</style>