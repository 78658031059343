<template>
    <div>
        <v-toolbar color="transparent" dense flat>
            <v-toolbar-title class="text-h6">
                {{ $t('AdminLeads.title') }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn class="text-none" color="accent" href="/leads/export" variant="flat">
                <v-icon start>mdi-microsoft-excel</v-icon>
                {{ $t('AdminLeads.table.downloadExcel') }}
            </v-btn>
        </v-toolbar>

        <v-card>
            <v-card-text class="pa-0">
                <v-data-table-server
                    v-model:items-per-page="tableParams.itemsPerPage"
                    v-model:page="tableParams.page"
                    :headers="selectedHeaders"
                    :group-by="tableParams.groupBy"
                    :items="items"
                    :items-length="itemsLength"
                    :items-per-page-text="$t('AdminLeads.table.itemsPerPage')"
                    :page-text="$t('AdminLeads.table.pageText', {page: meta.current_page, pages: meta.last_page})"
                    :items-per-page-options="[
                        {value: 10, title: '10'},
                        {value: 15, title: '15'},
                        {value: 20, title: '20'},
                        {value: 50, title: '50'},
                        {value: 100, title: '100'},
                        {value: 200, title: '200'},
                        {value: -1, title: '$vuetify.dataFooter.itemsPerPageAll'}
                    ]"
                    :loading="loading"
                    data-cy="admin-leads"
                    item-key="id"
                >
                    <template #group-header="{ item, columns, toggleGroup, isGroupOpen }">
                        <tr :ref="() => { groupHeaders[item.id] = { item, toggleGroup, isGroupOpen } }" class="bg-gray-200">
                            <td :colspan="columns.length">
                                <span class="ml-4">{{ $t('AdminLeads.table.folder.user') }}:</span>
                                <span v-if="item.items[0].raw.user.name" class="ml-2 font-medium">{{ item.items[0].raw.user.name }}</span>
                                <span class="mx-2">{{ item.items[0].raw.email ? item.items[0].raw.email : $t('OneWord.anonymized') }}</span>
                                <span class="ml-4 text-gray-500 lg:float-right lg:mr-6">
                                    {{ $t('OneWord.header.status') }}: {{ getUserStatus(item.items[0].raw) }}
                                </span>
                            </td>
                        </tr>
                    </template>

                    <template #top>
                        <v-toolbar color="transparent" flat>
                            <v-toolbar-title>
                                {{ $t('AdminTestator.personData') }}
                            </v-toolbar-title>

                            <v-spacer />

                            <table-column-selector 
                                name="admin-leads"
                                :headers="headers"
                                @update:columns="keys => selectedColumns = keys" 
                                @update:headers="headers => selectedHeaders = headers" 
                            />

                            <v-menu
                                :close-on-content-click="false"
                                location="bottom end"
                                width="300"
                            >
                                <template #activator="{ props, isActive }">
                                    <v-btn
                                        rounded="lg"
                                        v-bind="props"
                                        variant="outlined"
                                        height="40px"
                                        class="mr-4 border-black/35"
                                        :append-icon="isActive ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                                    >
                                        <template #prepend>
                                            <v-badge :model-value="tableParams.filters.ngo_is_visible !== 1" color="red" dot>
                                                <v-icon>mdi-filter-outline</v-icon>
                                            </v-badge>
                                        </template>
                                        {{ $t('OneWord.filter') }}
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item-title class="text-subtitle-2 mx-4 mb-1 text-gray-600">{{ $t('AdminLeads.table.filter.label') }}</v-list-item-title>
                                    <v-list-item>
                                        <v-select
                                            v-model="tableParams.filters.ngo_is_visible"
                                            :items="[
                                                {title: $t('AdminLeads.table.filter.ngoIsVisible.showAll'), value: null},
                                                {title: $t('AdminLeads.table.filter.ngoIsVisible.yes'), value: 1},
                                                {title: $t('AdminLeads.table.filter.ngoIsVisible.no'), value: 0},
                                            ]"
                                            density="compact"
                                            hide-details
                                            variant="outlined"
                                        />
                                    </v-list-item>
                                </v-list>
                            </v-menu>

                            <v-text-field
                                :model-value="tableParams.search"
                                :label="$t('OneWord.search')"
                                class="me-2"
                                density="compact"
                                flat
                                hide-details
                                max-width="300"
                                prepend-inner-icon="mdi-magnify"
                                rounded="lg"
                                single-line
                                variant="outlined"
                                clearable
                                @update:model-value="onSearchUpdate"
                            />
                        </v-toolbar>
                    </template>

                    <template #header.is_favored="{ column }">
                        <div class="v-data-table-header__content">
                            <v-tooltip location="bottom">
                                <template #activator="{ props }">
                                    <span v-bind="props">{{ column.title }}</span>
                                </template>
                                <span>{{ $t('AdminLeads.dataTable.isFavoredHeaderTooltip') }}</span>
                            </v-tooltip>
                        </div>
                    </template>

                    <template #item="{ item }">
                        <tr
                            v-for="version in sortedVersions(item)"
                            :key="version.id"
                            data-cy="admin-leads-table-version-row"
                            class="admin-leads-lead-row cursor-pointer hover:bg-gray-100/50"
                            @click="onRowClick(version)"
                        >
                            <td><!-- empty --></td>
                            <td v-if="selectedColumns.includes('is_favored')" class="text-center">
                                <template v-if="version.lead_type === 'will' && version.is_favored !== null">
                                    <v-icon v-if="version.is_favored" color="green">mdi-check-circle</v-icon>
                                    <v-icon v-else color="red">mdi-minus-circle-outline</v-icon>
                                </template>
                                <v-tooltip v-else location="bottom">
                                    <template #activator="{ props }">
                                        <span class="text-caption" v-bind="props">{{ $t('OneWord.notAvailableIcon') }}</span>
                                    </template>
                                    <span>{{ $t('OneWord.notAvailableToolTip') }}</span>
                                </v-tooltip>
                            </td>
                            <td v-if="selectedColumns.includes('folder')" class="whitespace-nowrap">
                                <span v-if="version.folder_own">{{ $t('AdminLeads.table.folder.user') }}</span>
                                <span v-else>{{ $t('AdminLeads.table.folder.others') }}<span v-if="version.folder_id">: {{ version.folder }}</span></span>
                            </td>
                            <td v-if="selectedColumns.includes('first_name')">
                                <span v-if="version.anonymized">({{ $t('OneWord.deleted') }})</span>
                                <span v-else-if="version.first_name">{{ version.first_name }}</span>
                                <span v-else class="text-gray-500">{{ $t('AdminLeads.table.noValue') }}</span>
                            </td>
                            <td v-if="selectedColumns.includes('last_name')">
                                <span v-if="version.anonymized">({{ $t('OneWord.deleted') }})</span>
                                <span v-else-if="version.last_name">{{ version.last_name }}</span>
                                <span v-else class="text-gray-500">{{ $t('AdminLeads.table.noValue') }}</span>
                            </td>
                            <td>{{ $t(`AdminLeads.leadType.items.${version.lead_type}`) }}</td>
                            <td class="whitespace-nowrap">{{ $filters.formatDateTime(version.created_at) }}</td>
                            <td v-if="selectedColumns.includes('ngo_thanks_computed')">
                                <v-icon v-if="version.ngo_thanks_computed === 2" color="blue">mdi-information</v-icon>
                                <v-icon v-else-if="version.ngo_thanks_computed === 1" color="green">mdi-check-circle</v-icon>
                                <v-icon v-else-if="version.ngo_thanks_computed === 0" color="red">mdi-minus-circle-outline</v-icon>
                                <v-tooltip v-else location="bottom">
                                    <template #activator="{ props }">
                                        <span class="text-caption" v-bind="props">{{ $t('OneWord.notAvailableIcon') }}</span>
                                    </template>
                                    <span>{{ $t('OneWord.notAvailableToolTip') }}</span>
                                </v-tooltip>
                            </td>
                            <td v-if="selectedColumns.includes('ngo_info')">
                                <v-icon v-if="version.ngo_info" color="green">mdi-check-circle</v-icon>
                                <v-icon v-else color="red">mdi-minus-circle-outline</v-icon>
                            </td>
                            <td v-if="selectedColumns.includes('lead_source')">{{ $t(`AdminLeads.leadSource.items.${version.lead_source}`) }}</td>
                            <td>{{ version.id }}</td>
                            <td v-if="selectedColumns.includes('ngo_is_visible')">
                                <v-icon v-if="version.ngo_is_visible" color="green">mdi-check-circle</v-icon>
                                <v-icon v-else color="red">mdi-minus-circle-outline</v-icon>
                            </td>
                            <td>
                                <lead-actions-menu :item="version" @error="errorToggle" @update:toggle="updateToggle" />
                            </td>
                        </tr>
                    </template>
                </v-data-table-server>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import {EventBus} from "@/utils/event-bus";
import {mapGetters} from "vuex";
import LeadActionsMenu from "@/components/Lead/LeadActionMenu.vue";
import TableColumnSelector from "@/components/TableColumnSelector.vue";

export default {
    name: "AdminLeads",
    components: {LeadActionsMenu, TableColumnSelector},
    data() {
        return {
            items: [],
            loading: false,
            meta: {
                total: null
            },
            options: {},

            tableParams: {
                page: 1,
                itemsPerPage: 10,
                filters: {
                    ngo_is_visible: 1,
                },
                search: null,
                groupBy: [
                    {
                        key: 'group_key',
                        order: 'asc',
                    },
                ],
            },

            totalLeads: 0,
            pageLeads: 0,
            
            groupHeaders: {},
            expandedGroups: [],

            selectedHeaders: [],
            selectedColumns: [],
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
        headers() {
            return [
                {title: this.$t('AdminLeadEdit.section.will.isFavouredInfo'), value: 'is_favored', hideable: true},
                {title: this.$t('AdminLeads.table.header.folder'), value: 'folder', hideable: true},
                {title: this.$t('OneWord.firstName'), value: 'first_name', hideable: true},
                {title: this.$t('OneWord.lastName'), value: 'last_name', hideable: true},
                {title: this.$t('AdminLeads.leadType.title'), value: 'lead_type'},
                {title: this.$t('OneWord.date'), value: 'created_at'},
                {title: this.$t('AdminTestator.ngoThanks'), value: 'ngo_thanks_computed', hideable: true},
                {title: this.$t('AdminTestator.ngoInfo'), value: 'ngo_info', hideable: true},
                {title: this.$t('AdminLeads.source'), value: 'lead_source', hideable: true},
                {title: this.$t('AdminLeads.ID'), value: 'id'},
                {title: this.$t('AdminLeads.table.ngoIsVisible'), value: 'ngo_is_visible', hideable: true},
                {title: '', value: 'actions'},
            ];
        },
        itemsLength() {
            if(_.isNull(this.meta.total)) {
                return this.tableParams.page * this.tableParams.itemsPerPage;
            }

            return this.meta.total;
        },
    },
    watch: {
        tableParams: {
            deep: true,
            handler() {
                this.load();
            }
        }
    },
    beforeUnmount() {
        this.storeTableParams();
        window.removeEventListener('beforeunload', this.storeTableParams);
    },
    mounted() {
        const storedSettingsJSON = localStorage.getItem('da.admin.leads.table.params');
        if (storedSettingsJSON) {
            _.assign(this.tableParams, JSON.parse(storedSettingsJSON));
        } else {
            this.load();
        }

        window.addEventListener('beforeunload', this.storeTableParams);
    },
    methods: {
        onSearchUpdate: _.debounce(function (newValue) {
            this.tableParams.search = newValue;
        }, 300),
        load() {
            this.loading = true;

            axios.get(`/webapi/leads`, {params: this.tableParams}).then(response => {
                this.items = response.data.data;
                this.meta = response.data.meta;
                this.totalLeads = response.data.total_leads;
                this.pageLeads = response.data.page_leads;
            }).catch(error => {
                EventBus.$emit('error', error.response.data.message);
            }).finally(() => {
                this.loading = false;

                this.$nextTick(() => {
                    this.toggleAllGroups();
                });
            });
        },
        storeTableParams() {
            window.localStorage.setItem('da.admin.leads.table.params', JSON.stringify(this.tableParams));
        },
        onRowClick(item) {
            this.$router.push({
                name: 'admin-lead-edit',
                params: {lead_id: item.id},
            });
        },
        sortPos(sortBy) {
            return _.findIndex(sortBy, {key: 'is_favored'}) + 1;
        },
        updateToggle() {
            this.load();
            EventBus.$emit('success');
        },
        errorToggle(message) {
            EventBus.$emit('error', message);
        },
        toggleAllGroups() {
            Object.values(this.groupHeaders).forEach(header => {
                if (!header.isGroupOpen(header.item)) {
                    header.toggleGroup(header.item);
                }
            });
        },
        sortedVersions(item) {
            let versions = _.concat([item], item.versions);
            
            // first, group versions by folder
            const folderGroups = _.groupBy(versions, 'folder');
            
            // then, sort folders by their most recent date
            const sortedFolders = _.orderBy(
                Object.values(folderGroups),
                items => _.maxBy(items, 'created_at').created_at,
                'desc'
            );
            
            // for each folder, group by lead_type and sort
            const result = _.flatMap(sortedFolders, folderItems => {
                // group by lead_type within the folder
                const typeGroups = _.groupBy(folderItems, 'lead_type');
                
                // then, sort lead_type groups by their most recent date
                const sortedTypes = _.orderBy(
                    Object.values(typeGroups),
                    group => _.maxBy(group, 'created_at').created_at,
                    'desc'
                );
                
                // then, sort items within each lead_type group by date
                return _.flatMap(sortedTypes, group => 
                    _.orderBy(group, 'created_at', 'desc')
                );
            });
            
            return result;
        },
        getUserStatus(lead) {
            if (lead.deletion_scheduled_at) {
                return moment(lead.deletion_scheduled_at).isAfter(moment())
                    ? this.$t('AdminLeads.table.headers.scheduledDeletionAt', {date: this.$filters.date(lead.deletion_scheduled_at)}) 
                    : this.$t('AdminLeads.table.headers.deletedAt', {date: this.$filters.date(lead.deletion_scheduled_at)});
            }

            return lead.user?.is_active ? this.$t('AdminLeads.table.headers.active') : this.$t('AdminLeads.table.headers.unknownUser');
        }
    }
}
</script>

<style lang="scss" scoped>
.v-data-table :deep(.v-data-table__th span) {
    white-space: nowrap;
}

.v-data-table :deep(.v-data-table__th:first-child),
.v-data-table :deep(.admin-leads-lead-row) td:first-child {
    display: none;
}
</style>
