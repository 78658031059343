<template>
    <v-card>
        <v-card-title class="text-h5 bg-primary text-white">
            {{ $t('TestamentCreated.ngoThanksTitle') }}
        </v-card-title>
        <v-card-text class="mt-4 text-base">
            <template v-if="hasProfile">
                <v-row v-for="profile in listOfAllNgo" :key="profile.id" data-cy="profile">
                    <v-col cols="4" class="d-flex align-center justify-center">
                        <v-img
                            v-if="profile.logo"
                            :src="profile.logo.url"
                            :alt="profile.company_name"
                            max-height="4rem"
                            max-width="12rem"
                        />
                    </v-col>
                    <v-col cols="8">
                        <div class="d-flex">
                            <div
                                v-if="hasPersonImage(profile)"
                                :style="{ backgroundImage: `url('${profile.person_image.url}')` }"
                                class="mx-auto size-20 rounded-full border border-solid border-gray-300 bg-cover bg-center bg-no-repeat"
                            />
                            <div
                                v-else
                                class="mx-auto size-20 rounded-full border border-solid border-gray-300 bg-cover bg-center bg-no-repeat"
                            >
                                <svg class="text-gray-200" viewBox="0 0 24 24">
                                    <path d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" fill="currentColor" />
                                </svg>
                            </div>
                            <div class="ml-2 grow text-base">
                                <div class="mt-2 text-lg font-bold">{{ profile.company_name }}</div>
                                {{ profile.first_name }} {{ profile.last_name }}
                            </div>
                        </div>
                    </v-col>
                </v-row>

                <hr class="my-4">
            </template>

            <v-row>
                <v-col>
                    <div v-if="tool === 'patientDecree'">
                        {{ $t(`Affiliate.ngoThanks.patientDecree.headline`) }}
                    </div>
                    <div v-else-if="tool === 'advanceCareDirective'">
                        {{ $t(`Affiliate.ngoThanks.advanceCareDirective.headline`) }}
                    </div>
                    <template v-else-if="isAtLeastOneNgoFavored">
                        <div class="text-subtitle-2" v-html="$t('Affiliate.ngoThanks.general.headline')" />

                        <v-switch
                            v-model="settings.ngoThanks"
                            :false-value="false"
                            :true-value="true"
                            density="compact"
                            hide-details
                            data-cy="switch-ngo-thanks"
                        >
                            <template #label>
                                <small v-html="$t('Affiliate.ngoThanks.general.acceptLabel', {listOfFavoredNgoNames: listOfFavoredNgoNames})" />
                            </template>
                        </v-switch>
                    </template>

                    <div class="text-subtitle-2 mt-4" v-html="$t('Affiliate.ngoInfo.general.headline')" />

                    <v-switch
                        v-model="settings.ngoInfo"
                        :false-value="false"
                        :true-value="true"
                        density="compact"
                        hide-details
                        data-cy="switch-ngo-info"
                    >
                        <template #label>
                            <small v-html="$t('Affiliate.ngoInfo.general.acceptLabel', {listOfAllNgoNames: listOfAllNgoNames})" />
                        </template>
                    </v-switch>

                    <small class="d-block mt-4" v-html="$t('NgoThanksDialog.privacyInfo')" />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                data-cy="ngo-thanks-save"
                variant="flat"
                @click="$emit('save', settings)"
            >
                {{ $t('OneWord.save') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'NgoThanksDialog',
    props: {
        ngoThanks: Boolean,
        ngoInfo: Boolean,
        profiles: {
            type: Array,
            default: () => [],
        },
        tool: String,
    },
    data() {
        return {
            settings: {
                ngoThanks: false,
                ngoInfo: false,
            }
        }
    },
    computed: {
        ...mapGetters('affiliateProfile', {
            affiliateProfile: 'profile',
            hasAffiliateProfile: 'hasProfile',
        }),
        ...mapGetters('env', {
            getCountry: 'getCountry',
        }),
        ...mapGetters('testament', [
            'isAtLeastOneNgoFavored',
            'testamentPeopleNgosFavored',
        ]),
        hasProfile() {
            return this.listOfAllNgo.length > 0;
        },
        listOfFavoredNgo() {
            return _.filter(this.profiles, profile => this.testamentPeopleNgosFavored.map(item => item.id).includes(profile.id.toString()));
        },
        listOfFavoredNgoNames() {
            return this.listOfFavoredNgo.map(profile => profile.company_name).join(', ');
        },
        listOfAllNgo() {
            let favoredNgos = _.cloneDeep(this.listOfFavoredNgo);
            if (this.hasAffiliateProfile) {
                favoredNgos.push(this.affiliateProfile);
            }
            favoredNgos = _.uniqBy(favoredNgos, 'id');
            return favoredNgos;
        },
        listOfAllNgoNames() {
            return this.listOfAllNgo.map(profile => profile.company_name).join(', ');
        }
    },
    mounted() {
        this.settings.ngoThanks = this.ngoThanks;
        this.settings.ngoInfo = this.ngoInfo;

        // in CH, ngo thanks is always pre-set to "true", but only if there is at least one NGO Favored.
        if (this.getCountry === 'CH') {
            if (this.isAtLeastOneNgoFavored) {
                // Nur dann, wenn auch mindestens eine NGO berücksichtigt wurde
                this.settings.ngoThanks = true;
            }
            if (this.tool === 'advanceCareDirective' || this.tool === 'patientDecree') {
                this.settings.ngoInfo = true;
            }
        }
    },
    methods: {
        hasPersonImage(profile) {
            return _.has(profile, 'person_image.url');
        },
    }
};
</script>

<style scoped>

</style>
