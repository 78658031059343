<template>
    <div v-if="webinar" class="px-2 py-0 sm:px-0 lg:py-6">
        <affiliate-header v-if="!isAuthenticated" class="text-center" />

        <v-card class="rounded-md p-10 lg:rounded-xl" :flat="!isAuthenticated">
            <v-row>
                <v-col>
                    <small class="font-weight-medium mb-1 block text-gray-500">{{ $t('OneWord.webinar') }}</small>

                    <h1 class="my-2 text-2xl font-medium">
                        {{ webinar.title }}
                    </h1>

                    <v-row class="my-6 border-y">
                        <v-col cols="12" lg="7">
                            <small class="font-weight-medium mb-1 block text-gray-500">{{ $t('OneWord.date') }}</small>
                            <pre class="text-h6">{{ localizeDate(webinar.date, 'dddd') }}, {{ localizeDate(webinar.date) }}</pre>
                        </v-col>
                        <v-col cols="12" lg="5">
                            <small class="font-weight-medium mb-1 block text-gray-500">{{ $t('Webinars.details.times') }} ({{ shortTimezone() }})</small>
                            <pre class="text-h6">{{ localizeTime(webinar.start_time) }} - {{ localizeTime(webinar.end_time) }}</pre>
                        </v-col>
                    </v-row>

                    <div class="mb-10 mt-8" v-html="$filters.nl2br(webinar.description)" />

                    <small 
                        v-if="webinar.status !== 'done'"
                        class="mt-6 block"
                        v-html="$t('Webinars.details.supportText', {email: webinar.presenter || getMailFromAddress})"
                    />
                </v-col>
            </v-row>
        </v-card>

        <v-card class="mt-6 rounded-md p-10 lg:rounded-xl" :flat="!isAuthenticated">
            <v-row>
                <v-col>
                    <h1 class="text-h6 mb-2">
                        {{ $t('Webinars.details.registration.headline') }}
                    </h1>

                    <v-alert v-if="registrationError" data-cy="registration-error" type="error" class="mt-8">
                        {{ $t('Webinars.details.registration.errorText') }}
                    </v-alert>

                    <div v-else-if="registered" data-cy="registration-success">
                        <v-alert type="success">
                            {{ $t('Webinars.details.registration.successText') }}
                        </v-alert>

                        <p>{{ $t('Webinars.details.registration.successInfo') }}</p>
                    </div>

                    <div v-if="webinar.status === 'done'" data-cy="registration-completed">
                        <v-alert type="info">
                            {{ $t('Webinars.details.completed') }}
                        </v-alert>
                    </div>
                    
                    <vee-form v-else-if="webinar.status !== 'done'" ref="webinarRegistration" @submit="register()">
                        <v-row class="mt-5">
                            <v-col>
                                <vee-field 
                                    v-slot="{ componentField, errors }"
                                    v-model="form.first_name"
                                    :label="$t('OneWord.firstName')" 
                                    rules="required"
                                    name="first_name"
                                >
                                    <v-text-field
                                        v-bind="componentField"
                                        :label="$t('OneWord.firstName')"
                                        :error-messages="errors"
                                        maxlength="255"
                                    />
                                </vee-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <vee-field 
                                    v-slot="{ componentField, errors }"
                                    v-model="form.last_name"
                                    :label="$t('OneWord.lastName')" 
                                    rules="required"
                                    name="last_name"
                                >
                                    <v-text-field
                                        v-bind="componentField"
                                        :label="$t('OneWord.lastName')"
                                        :error-messages="errors"
                                        maxlength="255"
                                    />
                                </vee-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <vee-field 
                                    v-slot="{ componentField, errors }"
                                    v-model="form.email"
                                    :label="$t('OneWord.email')" 
                                    rules="required|email"
                                    name="email"
                                >
                                    <v-text-field
                                        v-bind="componentField"
                                        :label="$t('OneWord.email')"
                                        :error-messages="errors"
                                    />
                                </vee-field>
                            </v-col>
                        </v-row>

                        <v-row v-if="1 === 2">
                            <v-col>
                                <v-textarea
                                    v-model="form.question"
                                    :label="$t('Webinar.details.registration.questionLabel')"
                                    rows="3"
                                    maxlength="255"
                                />
                            </v-col>
                        </v-row>

                        <v-row class="p-3 pt-0">
                            <v-switch
                                v-if="!isAuthenticated"
                                v-model="agreementAccepted"
                                :false-value="false"
                                :true-value="true"
                                data-cy="switch-affiliate-agreement"
                                density="compact"
                                class="mt-0 pt-0"
                                hide-details
                            >
                                <template #label>
                                    <small data-cy="accept-label" v-html="$t('Affiliate.simple.acceptLabel')" />
                                </template>
                            </v-switch>
                        </v-row>

                        <v-btn 
                            :loading="loading"
                            :disabled="!agreementAccepted && !isAuthenticated"
                            data-cy="btn-register"
                            color="primary"
                            variant="flat"
                            type="submit"
                            class="mt-5"
                        >
                            {{ $t('Webinars.details.registration.buttonRegister') }}
                        </v-btn>

                        <small class="mt-6 block">{{ $t('Webinars.details.registration.legalInfo') }}</small>
                    </vee-form>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import {EventBus} from '@/utils/event-bus';
import AffiliateHeader from '@/pages/affiliate/AffiliateHeader.vue';
import {Form as VeeForm, Field as VeeField} from "vee-validate";
import {shortTimezone, localizeDate, localizeTime} from '@/composables/Helpers';
import {mapGetters} from 'vuex';

export default {
    name: 'WebinarDetails',

    components: {
        AffiliateHeader,
        VeeField,
        VeeForm,
    },

    data() {
        return {
            webinar: {},
            form: {
                first_name: null,
                last_name: null,
                email: null,
                question: null,
            },
            loading: false,
            registered: false,
            registrationError: false,
            agreementAccepted: false,
        }
    },

    computed: {
        ...mapGetters('env', [
            'getLogo',
            'isAuthenticated',
            'getMailFromAddress'
        ]),
        ...mapGetters('user', {
            user: 'user',
        }),
    },

    mounted() {
        this.load();

        EventBus.$on('locale-changed', () => {
            this.load();
        });

        if (this.user && ! this.registered) {
            this.form.first_name = this.user.first_name;
            this.form.last_name = this.user.last_name;
            this.form.email = this.user.email;
        }
    },

    methods: {
        load() {
            this.loading = true;
            axios.get(`/webapi/webinars/${this.$route.params.webinar_id}`).then(response => {
                this.webinar = response.data.data;
            }).catch(() => {
                this.goBack();
            }).finally(() => {
                this.loading = false;
            });
        },

        register() {
            this.loading = true;
            axios.post(`/webapi/webinars/${this.$route.params.webinar_id}/registrations`, this.form)
                .then(() => {
                    this.registered = true;
                })
                .catch(() => {
                    this.registrationError = true;
                })
                .finally(() => this.loading = false)
        },

        goBack() {
            this.$router.push({path: `/profiles/${this.$route.params.profile_id}/webinars`});
        },

        localizeTime,
        localizeDate,
        shortTimezone,
    }
};
</script>
