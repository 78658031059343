import {assign, get} from 'lodash';
import router from '@/router';

const initialState = {
    step: 0,
    visitedSteps: [0],
    completed: false,
    agreementAccepted: false,
    ngoThanksSubmitted: false,

    testament: {
        familyData: {
            married: null,
            kids: null,
            parents: null,
            grandparents: null,
            greatgrandparents: null,
            de_matrimonial_property_regime: null, // Güterstand: separation_of_property, community_of_joint_gain
        },
        people: [],
        otherPeople: [],
        partner: null,
        testamentPeople: [],
        testator: null,
        ngos: [],

        // Sort order, internal helper
        sortIndex: 0,

        // Step 2b: Parents
        familyTree: [],
        mother: null,
        father: null,

        // Step 2c: Grandparents
        paternalFather: null,
        paternalMother: null,
        maternalFather: null,
        maternalMother: null,
        paternalFamilyTree: [],
        maternalFamilyTree: [],

        // Step 7: User email
        email: null,
    }
};

const state = _.cloneDeep({...initialState, ...{
    // Define here all properties which should
    // not be reset by the resetState method:
    folderId: null,
}});

const getters = {
    // the entire testament
    testament: state => state.testament,

    // general tool getters
    step: state => state.step,
    visitedSteps: state => state.visitedSteps,
    completed: state => state.completed,
    folderId: state => state.folderId,
    ngoThanksSubmitted: state => state.ngoThanksSubmitted,
    agreementAccepted: state => state.agreementAccepted,

    // specific testament getters
    familyData: state => state.testament.familyData,
    spouse: state => { return _.find(state.testament.testamentPeople, {type: 'spouse'}); },
    married: state => state.testament.familyData.married,
    kids: state => state.testament.familyData.kids,
    parents: state => state.testament.familyData.parents,
    grandparents: state => state.testament.familyData.grandparents,
    greatgrandparents: state => state.testament.familyData.greatgrandparents,
    people: state => state.testament.people,
    otherPeople: state => state.testament.otherPeople,
    testamentPeople: state => state.testament.testamentPeople,
    testamentPeopleNgos: state => {
        return _.reduce(_.filter(state.testament.testamentPeople, {type: 'ngo'}), (res, ngo) => {
            res.push({
                id: ngo.id.substring(4),
                quota: _.get(_.find(state.testament.testamentPeople, {id: ngo.id}), 'value', 0),
                amount: _.get(_.find(state.testament.testamentPeople, {id: ngo.id}), 'amount', 0),
                real_assets: _.get(_.find(state.testament.testamentPeople, {id: ngo.id}), 'realAssets', null),
                value_type: _.get(_.find(state.testament.testamentPeople, {id: ngo.id}), 'valueType', 'value'),
                'is_additional': ngo.is_additional,
                personalization: ngo.personalization,
            });
            return res;
        }, []);
    },
    testamentPeopleHumans: state => {
        return _.reject(state.testament.testamentPeople, {type: 'ngo'});
    },
    testator: state => state.testament.testator,
    ngos: state => state.testament.ngos,
    familyTree: state => state.testament.familyTree,
    mother: state => state.testament.mother,
    father: state => state.testament.father,
    paternalFather: state => state.testament.paternalFather,
    paternalMother: state => state.testament.paternalMother,
    maternalFather: state => state.testament.maternalFather,
    maternalMother: state => state.testament.maternalMother,
    maternalFamilyTree: state => state.testament.maternalFamilyTree,
    paternalFamilyTree: state => state.testament.paternalFamilyTree,
    getDeMatrimonialPropertyRegime: state => get(state.testament, 'familyData.de_matrimonial_property_regime'),
    testamentPeopleNgosFavored: (state, getters) => {
        return _.filter(_.filter(getters.testamentPeopleNgos, {is_additional: false}), ngo => {
            if (_.isString(ngo.real_assets) && ngo.real_assets.length >= 3) {
                return true;
            }

            return ngo.quota > 0 || ngo.amount > 0;
        });
    },
    isAtLeastOneNgoFavored: (state, getters) => {
        return _.reduce(_.filter(getters.testamentPeopleNgos, {is_additional: false}), (res, ngo) => {
            if (_.isString(ngo.real_assets) && ngo.real_assets.length >= 3) {
                return true;
            }

            return res || (ngo.quota > 0 || ngo.amount > 0);
        }, false);
    },
};

const actions = {
    set: ({commit}, value) => commit('set', value),
    patch: ({commit}, value) => commit('patch', value),
    setStep: ({commit}, value) => commit('step', value),
    nextStep: ({commit}, value) => commit('step', value),
    setCompleted: ({commit}) => commit('setCompleted'),
    setNgoThanksSubmitted: ({commit}) => commit('setNgoThanksSubmitted'),
    setFolderId: ({commit}, value) => commit('setFolderId', value),
    setFamily: ({commit}, familyData) => commit('setFamily', familyData),
    people: ({commit}, people) => commit('setPeople', people),
    addPeople: ({commit}, people) => commit('addPeople', people),
    otherPeople: ({commit}, people) => commit('setOtherPeople', people),
    partner: ({commit}, partner) => commit('setPartner', partner),
    personalize: ({commit}, {person, data}) => commit('setPersonalization', {person: person, data: data}),
    addToTestament: ({commit}, person) => commit('addToTestament', person),
    removeFromTestament: ({commit}, person) => commit('removeFromTestament', person),
    setValue: ({commit}, {person, value}) => commit('setValue', {person: person, value: value}),
    setTestator: ({commit}, testator) => commit('setTestator', testator),
    addNgo: ({commit}, ngo) => commit('addNgo', ngo),
    removeNgo: ({commit}, ngo) => commit('removeNgo', ngo),
    setAgreementAccepted: ({commit}, value) => commit('setAgreementAccepted', value),
    removeType: ({commit}, type) => commit('removeType', type),
    setFamilyTree: ({commit}, familyTree) => commit('familyTree', familyTree),
    setMother: ({commit}, mother) => commit('mother', mother),
    setFather: ({commit}, father) => commit('father', father),
    paternalFather: ({commit}, paternalFather) => commit('paternalFather', paternalFather),
    paternalMother: ({commit}, paternalMother) => commit('paternalMother', paternalMother),
    maternalFather: ({commit}, maternalFather) => commit('maternalFather', maternalFather),
    maternalMother: ({commit}, maternalMother) => commit('maternalMother', maternalMother),
    maternalFamilyTree: ({commit}, maternalFamilyTree) => commit('maternalFamilyTree', maternalFamilyTree),
    paternalFamilyTree: ({commit}, paternalFamilyTree) => commit('paternalFamilyTree', paternalFamilyTree),
    resetSliderValues: ({commit}) => commit('resetSliderValues'),
    reset: ({commit}) => commit('resetState'),
    restart: ({commit}) => commit('restartTool'),
};

const mutations = {
    set(state, value) {
        state.testament = value;
    },
    patch(state, value) {
        state.testament = _.merge(state.testament, value);
    },
    step(state, value) {
        state.step = parseInt(value) >= 0 ? parseInt(value) : parseInt(state.step) + 1;

        if (state.step !== _.last(state.visitedSteps)) {
            state.visitedSteps.push(state.step);
        }
    },
    setCompleted(state) {
        state.completed = true;
    },
    setFolderId(state, value) {
        state.folderId = isNaN(parseInt(value)) ? null : parseInt(value);
    },
    setNgoThanksSubmitted(state) {
        state.ngoThanksSubmitted = true;
    },
    setAgreementAccepted(state, value) {
        state.agreementAccepted = value;
    },
    setFamily(state, family) {
        assign(state.testament.familyData, family);
    },
    setPeople(state, people) {
        state.testament.people = people;
    },
    addPeople(state, people) {
        state.testament.people.concat(people);
    },
    setOtherPeople(state, people) {
        state.testament.otherPeople = people;
    },
    setPartner(state, partner) {
        state.testament.partner = partner;
    },
    setPersonalization(state, {person, data}) {
        let result = _.find(state.testament.testamentPeople, {id: person.id});
        if (result) {
            _.set(result, 'personalization', data);
        }
    },
    addToTestament(state, person) {
        let index = _.findIndex(state.testament.testamentPeople, {id: person.id}),
            res = state.testament.testamentPeople[index];

        person = Object.assign({
            legal: 0,
            min: 0,
            value: 0,
            amount: 0,
            personalization: {},
            valueType: 'value',
        }, res, person);

        _.set(person, 'parent', null);
        _.set(person, 'children', []);

        _.set(person, 'value', Number(person.value).toFixed(2));
        _.set(person, 'amount', Number(person.amount).toFixed(2));
        _.set(person, 'legal', Number(person.legal).toFixed(2));
        _.set(person, 'min', Number(person.min).toFixed(2));

        _.set(person, 'valueType', person.valueType);

        _.set(person, 'sortIndex', state.testament.sortIndex);

        state.testament.sortIndex++;

        if (res) {
            state.testament.testamentPeople.splice(index, 1, person);
        }
        else {
            state.testament.testamentPeople.push(person);
        }
    },
    removeFromTestament(state, person) {
        let index = _.findIndex(state.testament.testamentPeople, {id: person.id});

        if (index !== -1) {
            state.testament.testamentPeople.splice(index, 1);
        }
    },
    setValue(state, {person, value}) {
        let res = _.find(state.testament.testamentPeople, {id: person.id});
        if (res) {
            _.set(res, 'value', value);
        }
    },
    setTestator(state, testator) {
        state.testament.testator = testator;
    },
    addNgo(state, ngo) {
        let index = _.findIndex(state.testament.ngos, {id: ngo.id});
        if (index === -1) {
            state.testament.ngos.push(ngo);
        }
        else {
            state.testament.ngos.splice(index, 1, ngo);
        }
    },
    removeNgo(state, ngo) {
        let index = _.findIndex(state.testament.ngos, {id: ngo.id});
        if (index !== -1) {
            state.testament.ngos.splice(index, 1);
        }
    },
    removeType(state, type) {
        let itemsToRemove = _.filter(state.testament.testamentPeople, {type: type}),
            index;

        _.forEach(itemsToRemove, item => {
            index = _.findIndex(state.testament.testamentPeople, {id: item.id});
            state.testament.testamentPeople.splice(index, 1);
        });
    },
    familyTree(state, familyTree) {
        state.testament.familyTree = familyTree;
    },
    mother(state, mother) {
        state.testament.mother = mother;
    },
    father(state, father) {
        state.testament.father = father;
    },
    paternalFather(state, paternalFather) {
        state.testament.paternalFather = paternalFather;
    },
    paternalMother(state, paternalMother) {
        state.testament.paternalMother = paternalMother;
    },
    maternalFather(state, maternalFather) {
        state.testament.maternalFather = maternalFather;
    },
    maternalMother(state, maternalMother) {
        state.testament.maternalMother = maternalMother;
    },
    maternalFamilyTree(state, maternalFamilyTree) {
        state.testament.maternalFamilyTree = maternalFamilyTree;
    },
    paternalFamilyTree(state, paternalFamilyTree) {
        state.testament.paternalFamilyTree = paternalFamilyTree;
    },
    resetSliderValues(state) {
        // this will reset the assigned values of all people without
        // a legal minimum value to 0 (to prevent totals of > 100%)
        let people = _.filter(state.testament.testamentPeople, item => {
            return _.get(item, 'min', 0) <= 0;
        });

        _.forEach(people, person => {
            person.value = 0;
        });
    },
    resetState(state) {
        Object.assign(state, _.cloneDeep(initialState));
    },
    restartTool(state) {
        mutations.resetState(state);
        router.push({name: 'testament-tool'});
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
