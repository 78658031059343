<template>
    <v-snackbar
        v-model="snackbar"
        multi-line
        :color="color"
        :data-cy="'snackbar-' + type"
        location="top"
    >
        {{ text }}
        <v-btn
            color="white"
            variant="text"
            class="float-right"
            data-cy="btn-snackbar-close"
            @click="snackbar = false"
        >
            {{ $t('OneWord.close') }}
        </v-btn>
    </v-snackbar>
</template>

<script>
import {EventBus} from '../utils/event-bus';

export default {
    name: 'TopSnackbar',
    data() {
        return {
            color: 'red darken-2',
            text: null,
            snackbar: false,
            type: 'success',
        };
    },
    mounted() {
        EventBus.$on('success', message => {
            this.text = message || this.$t('OneWord.saveSuccess');
            this.type = 'success';
            this.snackbar = true;
            this.color = 'green darken-2';
        });
        EventBus.$on('error', message => {
            this.text = message || this.$t('OneWord.generalError');
            this.type = 'error';
            this.snackbar = true;
            this.color = 'red darken-2';
        });
        EventBus.$on('info', message => {
            this.text = message;
            this.type = 'info';
            this.snackbar = true;
            this.color = 'blue darken-2';
        });
    }
};
</script>

<style scoped>

</style>
