<template>
    <div class="-mb-12 h-full pb-8">
        <v-card 
            flat
            :data-cy="dataCy" 
            class="mx-auto mb-4 mt-8 h-full min-w-80 max-w-md overflow-visible rounded-lg"
            :class="!latestVersion ? 'border border-dashed !bg-transparent opacity-60 hover:!opacity-100' : null"
        >
            <v-menu v-if="latestVersion" location="left">
                <template #activator="{ props: menuProps }">
                    <v-tooltip location="bottom">
                        <template #activator="{ props: tooltipProps }">
                            <v-btn
                                color="grey"
                                theme="dark"
                                icon
                                variant="text"
                                class="ma-2 right-0"
                                position="absolute"
                                data-cy="btn-context-menu"
                                v-bind="{ ...tooltipProps, ...menuProps }"
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('OneWord.more') }}</span>
                    </v-tooltip>
                </template>

                <v-list data-cy="context-menu-list" density="compact">
                    <v-list-subheader>{{ $t('OneWord.mark') }}</v-list-subheader>
                    <v-list-item data-cy="btn-mark-written" @click="markAs('written')">
                        <template #prepend>
                            <v-icon>mdi-pen</v-icon>
                        </template>
                        <v-list-item-title>{{ $t('MyDocumentItem.markAsWritten') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item data-cy="btn-mark-signed" @click="markAs('signed')">
                        <template #prepend>
                            <v-icon>mdi-signature-freehand</v-icon>
                        </template>
                        <v-list-item-title>{{ $t('MyDocumentItem.markAsSigned') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item data-cy="btn-mark-archived" @click="markAs('archived')">
                        <template #prepend>
                            <v-icon>mdi-archive</v-icon>
                        </template>
                        <v-list-item-title>{{ $t('MyDocumentItem.markAsArchived') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item data-cy="btn-reset-marks" @click="resetMarks()">
                        <template #prepend>
                            <v-icon>mdi-undo</v-icon>
                        </template>
                        <v-list-item-title>{{ $t('OneWord.reset') }}</v-list-item-title>
                    </v-list-item>
                    <v-divider class="my-2 opacity-60" />
                    <v-list-subheader>{{ $t('OneWord.latestVersion') }}</v-list-subheader>
                    <v-list-item v-if="latestVersion.download_url" @click="download(latestVersion)">
                        <template #prepend>
                            <v-icon>mdi-download</v-icon>
                        </template>
                        <v-list-item-title>{{ $t('OneWord.download') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item data-cy="btn-move" @click="$emit('move', latestVersion)">
                        <template #prepend>
                            <v-icon>mdi-account-switch</v-icon>
                        </template>
                        <v-list-item-title>{{ $t('OneWord.move') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item data-cy="btn-delete" @click="remove(latestVersion, true)">
                        <template #prepend>
                            <v-icon>mdi-delete</v-icon>
                        </template>
                        <v-list-item-title>{{ $t('OneWord.delete') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <div class="px-6 pb-4 pt-6 text-center">
                <div class="bg-primary-lighten-1 absolute left-1/2 size-12 -translate-x-1/2 -translate-y-full rounded-full p-2.5">
                    <v-icon size="1.8rem" color="white">{{ tool.icon }}</v-icon>
                </div>
            </div>

            <v-card-text class="mt-4 py-0 text-center">
                <div class="text-h5 text-truncate text-nowrap font-medium">{{ tool.title }}</div>

                <template v-if="latestVersion">
                    <div class="text-small mb-6 mt-2 text-nowrap text-gray-400">
                        {{ $t('MyDocument.currentVersion', {date: $filters.date(latestVersion.created_at)}) }}
                    </div>

                    <v-chip
                        v-if="recentlyCreated(latestVersion)"
                        prepend-icon="mdi-star"
                        data-cy="recently-created"
                        class="ma-2 absolute left-2 top-2"
                        color="orange"
                        size="small"
                    >
                        <span class="text-orange-500">{{ $t('MyDocument.recentlyCreated') }}</span>
                    </v-chip>

                    <v-btn
                        v-if="latestVersion.download_url"
                        variant="flat"
                        color="primary"
                        prepend-icon="mdi-download"
                        data-cy="btn-download-pdf"
                        @click="download(latestVersion)"
                    >
                        {{ $t('OneWord.downloadPdf') }}
                    </v-btn>

                    <v-divider class="mt-6 opacity-60" />

                    <document-status :document="latestVersion" @mark="markAs" />

                    <v-divider class="mt-2 opacity-60" />
                </template>

                <template v-else>
                    <div class="my-12 px-8 text-base text-gray-500" v-html="$t('MyDocument.emptyDocument')" />

                    <v-btn
                        variant="outlined"
                        color="primary"
                        prepend-icon="mdi-plus"
                        class="mb-8"
                        data-cy="btn-create-new"
                        @click="createDocument()"
                    >
                        {{ $t('VerifyEmail.btnNext') }}
                    </v-btn>
                </template>
            </v-card-text>

            <div v-if="versionCount" class="p-6 text-center">
                <v-btn
                    v-if="latestVersion.download_url"
                    variant="outlined"
                    color="primary"
                    prepend-icon="mdi-plus"
                    data-cy="btn-create-version"
                    @click.stop="createDocument(latestVersion)"
                >
                    {{ $t('MyDocument.newVersion') }}
                </v-btn>
                <br>
                <a 
                    v-if="documents.length > 1"
                    href="#"
                    data-cy="btn-show-versions"
                    class="mt-4 inline-block text-sm underline-offset-4 hover:underline hover:decoration-black/10"
                    @click.prevent="showVersions = true"
                >
                    {{ $t('MyDocument.manageVersions') }}
                </a>
            </div>
        </v-card>

        <v-dialog 
            v-model="showVersions"
            data-cy="dialog-versions"
            :fullscreen="$vuetify.display.mdAndDown" 
            max-width="800"
        >
            <v-card>
                <v-card-title class="bg-primary-lighten-2 text-white">
                    <h4 class="pt-1 text-base font-normal">{{ folder.title }}</h4>
                    {{ tool.title }} - <span data-cy="versions-count">{{ $t('MyDocument.allVersions', {count: documents.length}) }}</span>
                    <v-spacer />
                    <v-btn 
                        icon="mdi-close" 
                        size="small" 
                        variant="text" 
                        data-cy="btn-close"
                        class="absolute right-0 top-0 m-2"
                        @click="showVersions = false" 
                    />
                </v-card-title>

                <v-card-text class="text-medium-emphasis pa-0 m-0">
                    <v-table
                        data-cy="my-document-versions"
                        fixed-header
                        height="50vh"
                        dense
                    >
                        <template #default>
                            <thead>
                                <tr>
                                    <th class="text-left">{{ $t('MyDocument.documentVersions.date') }}</th>
                                    <th class="text-left">{{ $t('MyDocument.documentVersions.status') }}</th>
                                    <th class="text-left">{{ $t('MyDocument.documentVersions.person') }}</th>
                                    <th><!-- actions --></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr 
                                    v-for="(document, index) in documents"
                                    :key="document.id"
                                    :data-cy="'version-' + index"
                                >
                                    <td>{{ localizeDate(document.created_at) }}</td>
                                    <td>{{ getStatus(document) }}</td>
                                    <td>{{ document.person_name || '–' }}</td>
                                    <td class="whitespace-nowrap text-right">
                                        <v-tooltip v-if="document.download_url" location="bottom">
                                            <template #activator="{ props }">
                                                <v-btn
                                                    v-bind="props"
                                                    color="grey"
                                                    theme="dark"
                                                    icon
                                                    variant="text"
                                                    size="small"
                                                    @click="download(document)"
                                                >
                                                    <v-icon size="large">mdi-tray-arrow-down</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('OneWord.download') }}</span>
                                        </v-tooltip>

                                        <v-tooltip location="bottom">
                                            <template #activator="{ props }">
                                                <v-btn
                                                    v-bind="props"
                                                    color="grey"
                                                    theme="dark"
                                                    icon
                                                    variant="text"
                                                    size="small"
                                                    data-cy="btn-move"
                                                    @click="$emit('move', document)"
                                                >
                                                    <v-icon size="large">mdi-account-switch</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('OneWord.move') }}</span>
                                        </v-tooltip>

                                        <v-tooltip location="bottom">
                                            <template #activator="{ props }">
                                                <v-btn
                                                    v-bind="props"
                                                    color="grey"
                                                    theme="dark"
                                                    icon="mdi-trash-can-outline"
                                                    variant="text"
                                                    data-cy="btn-delete"
                                                    @click="remove(document)"
                                                />
                                            </template>
                                            <span>{{ $t('OneWord.delete') }}</span>
                                        </v-tooltip>
                                    </td>
                                </tr>                            
                            </tbody>
                        </template>
                    </v-table>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DocumentStatus from './DocumentStatus';
import {localizeDate} from '@/composables/Helpers';
import {EventBus} from '@/utils/event-bus';

export default {
    name: 'MyDocument',
    components: {
        DocumentStatus
    },
    props: {
        documents: {
            type: Array,
            default() {
                return [];
            }
        },
        folder: {
            type: Object,
            default() {
                return {};
            },
        },
        type: {
            type: String,
            default: 'testament',
        },
    },
    data() {
        return {
            showVersions: false,
            tools: {
                testament: {
                    name: 'testament',
                    title: this.$t('OneWord.testament'),
                    icon: 'mdi-gavel',
                    apiSegment: 'testaments',
                    urlSegment: 'testament-tool',
                },
                patient_decree: {
                    name: 'patientDecree',
                    title: this.$t('OneWord.patientDecree'),
                    icon: 'mdi-file-sign',
                    apiSegment: 'patient-decree',
                    urlSegment: 'patient-decree-tool',
                },
                advance_care_directive: {
                    name: 'advanceCareDirective',
                    title: this.$t('AppUserNav.advance_care_directive'),
                    icon: 'mdi-file-account-outline',
                    apiSegment: 'advance-care-directive',
                    urlSegment: 'advance-care-directive-tool',
                }
            }
        }
    },
    computed: {
        versionCount() {
            return this.documents.length;
        },
        isWritten() {
            return this.latestVersion?.written_at;
        },
        isSigned() {
            return this.latestVersion?.signed_at;
        },
        isArchived() {
            return this.latestVersion?.archived_at;
        },
        latestVersion() {
            return this.versionCount > 0 ? this.documents[0] : null;
        },
        tool() {
            return this.tools[this.type] || {};
        },
        dataCy() {
            return 'my-documents-' + this.type + (!this.latestVersion ? '-empty' : '');
        },
    },
    methods: {
        getStatus(document) {
            if (document.archived_at) {
                return this.$t('MyDocuments.archived');
            } else if (document.signed_at) {
                return this.$t('MyDocuments.signed');
            } else if (document.written_at) {
                return document.type === 'patient_decree' ? this.$t('MyDocuments.printed') : this.$t('MyDocuments.written');
            } else {
                return '–';
            }
        },
        recentlyCreated(document) {
            return moment(document.created_at).isAfter(moment().subtract(1, 'hour'));
        },
        markAs(type) {
            axios.post(`/webapi/${this.tool.apiSegment}/${this.latestVersion.id}/mark`, {
                mark: type,
            }).then(() => {
                this.$emit('reload');
            });
        },
        resetMarks() {
            axios.post(`/webapi/${this.tool.apiSegment}/${this.latestVersion.id}/reset-marks`).then(() => {
                this.$emit('reload');
            });
        },
        createDocument(document) {
            // make sure the tool is reset (if already started)
            this.$store.dispatch(this.tool.name + '/reset');

            if (document) {
                switch (this.tool.name) {
                case 'patientDecree':
                    this.$router.push({
                        name: 'patientenverfuegung-edit',
                        params: {
                            patient_decree_id: document.id
                        },
                        query: {
                            folder: this.folder.id,
                        }
                    });
                    break;
                case 'advanceCareDirective':
                    this.$router.push({
                        name: 'advance-care-directive-edit',
                        params: {
                            advance_care_directive_id: document.id
                        },
                        query: {
                            folder: this.folder.id,
                        }
                    });
                    break;
                case 'testament':
                    this.$router.push({
                        name: 'testament-edit',
                        params: {
                            testament_id: document.id
                        },
                        query: {
                            folder: this.folder.id,
                        }
                    });
                }
            } else {
                this.$router.push({
                    name: this.tool.urlSegment,
                    query: {
                        folder: this.folder.id,
                    }
                });
            }
        },
        download(document) {
            window.open(document.download_url, '_blank');
        },
        remove(document, isLatestVersion = false) {
            let documentName = null;

            switch (document.type) {
            case 'testament':
                documentName = `${this.$t('OneWord.testament')} ${document.person_name}`;
                break;
            case 'patient_decree':
                documentName = `${this.$t('OneWord.patientDecree')} ${document.person_name}`;
                break;
            case 'advance_care_directive':
                documentName = `${this.$t('AdvanceCareDirectiveTool.title')} ${document.person_name}`;
                break;
            }

            if (isLatestVersion) {
                documentName += ' (' + this.$t('MyDocument.currentVersion', {date: localizeDate(document.created_at)}) + ')';
            } else {
                documentName += ' (' + this.$t('MyDocument.documentVersions.date') + ' ' + localizeDate(document.created_at) + ')';
            }

            this.$root.$confirm(this.$t('OneWord.delete'), this.$t('ConfirmDelete', {parameter: documentName})).
                then(confirm => {
                    if (confirm) {
                        if (document.type === 'testament') {
                            axios.delete(`/webapi/testaments/${document.id}`).then(() => {
                                EventBus.$emit('success', this.$t('OneWord.itemDeleteSuccess', {item: documentName}));
                                this.$emit('reload');
                            });
                        }

                        if (document.type === 'patient_decree') {
                            axios.delete(`/webapi/patient-decree/${document.id}`).then(() => {
                                EventBus.$emit('success', this.$t('OneWord.itemDeleteSuccess', {item: documentName}));
                                this.$emit('reload');
                            });
                        }

                        if (document.type === 'advance_care_directive') {
                            axios.delete(`/webapi/advance-care-directive/${document.id}`).then(() => {
                                EventBus.$emit('success', this.$t('OneWord.itemDeleteSuccess', {item: documentName}));
                                this.$emit('reload');
                            });
                        }
                    }
                });
        },
        localizeDate,
    },
};
</script>

<style lang="scss" scoped>

</style>
