<template>
    <v-menu
        :close-on-content-click="false"
        location="bottom end"
        width="300"
    >
        <template #activator="{ props }">
            <v-btn
                rounded="lg"
                v-bind="props"
                variant="outlined"
                height="40px"
                class="mr-4 border-black/35"
            >
                <template #prepend>
                    <v-badge 
                        :model-value="selectedColumns.length != selectableColumns.length"
                        color="red"
                        dot
                    >
                        <v-icon v-if="selectedColumns.length != selectableColumns.length">mdi-eye-plus</v-icon>
                        <v-icon v-else>mdi-eye-check</v-icon>
                    </v-badge>
                </template>
                {{ $t('TableColumnSelector.columns') }}
            </v-btn>
        </template>

        <v-list v-model:selected="selectedColumns" select-strategy="leaf">
            <v-list-item class="border-b border-gray-200">
                <template #prepend>
                    <v-list-item-action start>
                        <v-checkbox-btn 
                            :model-value="selectedColumns.length === selectableColumns.length"
                            :indeterminate="selectedColumns.length > 0 && selectedColumns.length < selectableColumns.length"
                            @click="selectedColumns = selectedColumns.length === selectableColumns.length ? [] : selectableColumns.map(col => col.value)"
                        />
                    </v-list-item-action>
                </template>
                <span class="mb-3 mt-2 text-sm text-gray-600">{{ $t('TableColumnSelector.selectTitle') }}:</span>
            </v-list-item>
            <v-list-item
                v-for="column in selectableColumns"
                :key="column.value"
                :title="column.title"
                :value="column.value"
                class="select-none"
            >
                <template #prepend="{ isSelected }">
                    <v-list-item-action start>
                        <v-checkbox-btn :model-value="isSelected" />
                    </v-list-item-action>
                </template>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useUxStorage } from '@/composables/UxStorage';

const emit = defineEmits(['update:headers', 'update:columns']);

const { name, headers, hideableParam } = defineProps({
    name: {
        type: String,
        required: true,
    },
    headers: {
        type: Array,
        required: true,
    },
    hideableParam: {
        type: String,
        default: 'hideable',
    },
});

const selectedColumns = ref([]);

const uxStorage = useUxStorage(name);

const selectableColumns = computed(() => {
    return headers.filter(column => column[hideableParam]);
});

const versionHash = computed(() => {
    return uxStorage.makeVersionHash(headers.map(col => col.value).sort().join('.'));
});

onMounted(() => {
    const storedVersionHash = uxStorage.getVersion();
    
    if (!storedVersionHash) {
        selectedColumns.value = selectableColumns.value.map(col => col.value);
    }

    if (uxStorage.getData()) {
        selectedColumns.value = uxStorage.getData();
    }

    if (!storedVersionHash || storedVersionHash !== versionHash.value) {
        uxStorage.setVersion(versionHash.value);
        uxStorage.clearData();

        // remove all columns from the selectedColumns that are not in the new headers
        selectedColumns.value = selectedColumns.value.filter(col => headers.some(header => header.value === col));
        return;
    }
});

watch(selectedColumns, (keys) => {
    uxStorage.setData(keys);
    emit('update:headers', headers.filter(header => (header[hideableParam] && keys.includes(header.value)) || !header[hideableParam]));
    emit('update:columns', keys);
});
</script>

<style scoped>

</style>