<template>
    <div data-cy="advanceCareDirective-tool">
        <v-row class="mt-0">
            <v-col cols="12" md="6">
                <h1 class="text-h6 mb-6">
                    {{ $t('AppUserNav.advance_care_directive') }}
                </h1>
            </v-col>

            <v-col cols="12" md="6" class="md:text-right">
                <tool-folder tool="advanceCareDirective" />
            </v-col>
        </v-row>

        <v-sheet
            v-if="!isCompleted"
            :class="{'elevation-1': $vuetify.display.lgAndUp}"
            class="pa-4"
        >
            <tool-stepper
                :step="step"
                :steps="steps"
            />

            <component
                :is="stepComponent"
                @back="back"
                @next="next"
            />
        </v-sheet>
    </div>
</template>

<script>
import ToolFolder from '@/components/ToolFolder';
import ToolStepper from '@/components/ToolStepper';
import {mapActions, mapGetters} from 'vuex';
import { defineAsyncComponent } from 'vue';

export default {
    name: 'AdvanceCareDirectiveTool',
    components: {
        ToolFolder,
        ToolStepper,
        AdvanceCareDirectiveStep1: defineAsyncComponent(() => import('@/pages/advance-care-directive/AdvanceCareDirectiveStep1.vue')),
        AdvanceCareDirectiveStep2: defineAsyncComponent(() => import('@/pages/advance-care-directive/AdvanceCareDirectiveStep2.vue')),
        AdvanceCareDirectiveStep3: defineAsyncComponent(() => import('@/pages/advance-care-directive/AdvanceCareDirectiveStep3.vue')),
        AdvanceCareDirectiveStep4: defineAsyncComponent(() => import('@/pages/advance-care-directive/AdvanceCareDirectiveStep4.vue')),
        AdvanceCareDirectiveStep5: defineAsyncComponent(() => import('@/pages/advance-care-directive/AdvanceCareDirectiveStep5.vue')),
        AdvanceCareDirectiveStep6: defineAsyncComponent(() => import('@/pages/advance-care-directive/AdvanceCareDirectiveStep6.vue')),
    },
    beforeRouteEnter(to, from, next) {
        if (to.name === 'advance-care-directive-edit') {
            axios.get(`/webapi/advance-care-directive/${to.params.advance_care_directive_id}`).then(response => {
                next(vm => {
                    vm.resetAdvanceCareDirective();
                    vm.setAdvanceCareDirective(_.get(response, 'data.data'));
                    vm.setStep(1);
                });
            }).catch(() => {
                next({name: 'my-documents'});
            });
        } else {
            next();
        }
    },
    computed: {
        ...mapGetters('affiliateProfile', {
            affiliateProfile: 'profile',
            hasAffiliateProfile: 'hasProfile',
        }),
        ...mapGetters('advanceCareDirective', [
            'getAdvanceCareDirective',
            'step',
        ]),
        isCompleted() {
            return this.step > 6;
        },
        stepComponent() {
            return `AdvanceCareDirectiveStep${this.step}`;
        },
        steps() {
            return [
                {
                    step: 1,
                    text: this.$t('AdvanceCareDirectiveTool.patientData'),
                }, {
                    step: 2,
                    text: this.$t('AdvanceCareDirectiveTool.authorizedRepresentative'),
                }, {
                    step: 3,
                    text: this.$t('AdvanceCareDirectiveTool.replacementPerson1'),
                    optional: true,
                }, {
                    step: 4,
                    text: this.$t('AdvanceCareDirectiveTool.replacementPerson2'),
                    optional: true,
                }, {
                    step: 5,
                    text: this.$t('AdvanceCareDirectiveTool.questions'),
                }, {
                    step: 6,
                    text: this.$t('AdvanceCareDirectiveTool.generatePdf'),
                },
            ];
        },
    },
    mounted() {
        let profileSlug = _.get(this.$route.params, 'profile_slug', false);
        if (profileSlug) {
            this.loadAffiliate(profileSlug);
        }
    },
    methods: {
        ...mapActions('affiliateProfile', {
            loadAffiliate: 'load',
        }),

        ...mapActions('advanceCareDirective', {
            setStep: 'setStep',
            setAdvanceCareDirective: 'set',
            resetAdvanceCareDirective: 'reset',
        }),

        next() {
            if (
                this.step === 3
                && _.get(this.getAdvanceCareDirective, 'replacement_person_1.active', true) === false
            ) {
                this.setStep(this.step + 2);
            } else if (this.isCompleted) {
                this.$router.push({name: 'advance-care-directive-completed'});
            } else {
                this.setStep(this.step + 1);
            }
        },
    
        back() {
            this.setStep(this.step - 1);
        },
    },
};
</script>

<style scoped>

</style>
