export function useUxStorage(name) {

    const key = `da.ux.${name}`;

    const getItem = () => {
        return JSON.parse(localStorage.getItem(key)) ?? {
            version: null, 
            data: null,
        };
    }

    const setItem = (value) => {
        localStorage.setItem(key, JSON.stringify(value));
    }

    const getVersion = () => {
        return getItem().version;
    }

    const getData = () => {
        return getItem().data;
    }

    const setVersion = (value) => {
        const item = getItem();
        item.version = value;
        setItem(item);
    }

    const setData = (value) => {
        const item = getItem();
        item.data = value;
        setItem(item);
    }

    const clearData = () => {
        setData(null);
    }

    const makeVersionHash = (input) => {
        return btoa(input);
    }

    return { 
        getVersion,
        getData,
        setVersion,
        setData,
        clearData,
        makeVersionHash,
    }
}