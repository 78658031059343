<template>
    <div>
        <h1 class="text-h6 mb-2">
            {{ $t('AdminDownloads.title') }}
        </h1>

        <v-card v-for="category in contentCategories" :key="`category-${category.id}`" class="mt-4">
            <v-card-title>
                {{ category.name }}
            </v-card-title>
            
            <v-card-text>
                <v-alert v-if="category.info" type="info">
                    <span v-html="category.info" />
                </v-alert>
            </v-card-text>

            <v-data-table
                :headers="downloadHeaders"
                :items="category.downloads"
                class="content-download"
                disable-pagination
                hide-default-footer
                hide-default-header
            >
                <template #item.info="{item}">
                    <small class="text--secondary font-italic">{{ item.info }}</small>
                </template>
                <template #item.download="{item}">
                    <v-btn
                        v-if="item.external_link"
                        :block="$vuetify.display.mdAndDown"
                        :href="item.external_link"
                        color="primary"
                        size="small"
                        target="_blank"
                        variant="flat"
                    >
                        <v-icon start>mdi-open-in-new</v-icon>
                        {{ $t('OneWord.show') }}
                    </v-btn>
                    <v-btn
                        v-else
                        :block="$vuetify.display.mdAndDown"
                        color="primary"
                        size="small"
                        variant="flat"
                        @click="download(item)"
                    >
                        <v-icon start>mdi-download</v-icon>
                        {{ $t('OneWord.download') }}
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {EventBus} from '@/utils/event-bus';

export default {
    name: 'AdminDownloads',
    data() {
        return {
            loading: false,
            contentCategories: [],
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
        downloadHeaders() {
            return [
                {value: 'name', width: '50%'},
                {value: 'info'},
                {value: 'status', width: '200px'},
                {value: 'download', width: '200px', align: 'end'},
            ];
        },
    },
    mounted() {
        EventBus.$on('locale-changed', this.load);
        this.load();
    },
    beforeUnmount() {
        EventBus.$off('locale-changed', this.load);
    },
    methods: {
        load() {
            this.loading = true;

            axios.get(`/webapi/accounts/${this.user.account_id}/content-categories`)
                .then(response => {
                    this.contentCategories = response.data.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        download(item) {
            window.location.href = item.url;
        },
        openExternalLink(item) {
            window.open(item.external_link, '_blank');
        }
    }
};
</script>

<style lang="scss" scoped>
.content-download {
  :deep(.v-data-table__mobile-row__header) {
    display: none;
  }

  :deep(.v-data-table__mobile-row__cell) {
    @apply w-full;
    @apply text-left;
  }
}
</style>
