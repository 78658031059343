<template>
    <div>
        <v-row class="mt-0">
            <v-col cols="12" md="6">
                <h1 class="text-h6">
                    {{ $t('MyDocuments.title') }}
                </h1>
            </v-col>
            <v-col cols="12" md="6" class="md:text-right">
                <v-btn 
                    color="primary"
                    variant="outlined"
                    data-cy="btn-person-add"
                    @click="showNewFolder = true"
                >
                    {{ $t('MyDocuments.addFolder') }}
                </v-btn>
            </v-col>
        </v-row>

        <v-expansion-panels v-model="panels" data-cy="my-documents" class="mb-8 mt-4">
            <v-expansion-panel
                v-for="(folder, index) in folders"
                :key="folder.id"
                :data-cy="'my-documents-folder-' + index"
                elevation="0"
                rounded="lg"
                bg-color="#f0f0f0"
            >
                <v-expansion-panel-title class="text-lg">
                    <template #default="{ expanded }">
                        <span>{{ folder.title }}</span>
                        <v-btn 
                            v-if="folder.id && expanded"
                            variant="tonal"
                            data-cy="btn-folder-edit"
                            prepend-icon="mdi-pencil"
                            class="absolute right-16"
                            @click.stop="editFolder(folder)"
                        >
                            {{ $t('OneWord.edit') }}
                        </v-btn>
                    </template>
                </v-expansion-panel-title>
                <v-expansion-panel-text class="py-2">
                    <v-row>
                        <v-col cols="12" md="6" lg="4">
                            <my-document
                                type="testament"
                                :folder="folder"
                                :documents="filterDocuments(folder, 'testament')"
                                @reload="load"
                                @move="move"
                            />
                        </v-col>
                        <v-col v-if="$hasFeature($Features.PatientDecree)" cols="12" md="6" lg="4">
                            <my-document
                                type="patient_decree"
                                :folder="folder"
                                :documents="filterDocuments(folder, 'patient_decree')"
                                @reload="load"
                                @move="move"
                            />
                        </v-col>
                        <v-col v-if="$hasFeature($Features.AdvanceCareDirective)" cols="12" md="6" lg="4">
                            <my-document
                                type="advance_care_directive"
                                :folder="folder"
                                :documents="filterDocuments(folder, 'advance_care_directive')"
                                @reload="load"
                                @move="move"
                            />
                        </v-col>
                    </v-row>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-dialog 
            v-model="showMoveDocument"
            data-cy="dialog-move-document"
            :fullscreen="$vuetify.display.mdAndDown"
            max-width="400"
        >
            <v-card>
                <v-card-title class="bg-primary-lighten-2 text-white">
                    {{ $t('MyDocuments.moveDocument.title') }}
                    <v-spacer />
                    <v-btn 
                        icon="mdi-close" 
                        size="small" 
                        variant="text" 
                        class="absolute right-0 top-0 m-1"
                        @click="closeMoveDocumentDialogue" 
                    />
                </v-card-title>

                <v-card-text class="pa-0 m-0">
                    <v-list v-if="movableFolders.length">
                        <v-list-subheader>{{ $t('MyDocuments.moveDocument.chooseFolder') }}</v-list-subheader>
                        <v-list-item 
                            v-for="(folder, index) in movableFolders"
                            :key="folder.id"
                            :data-cy="'move-folder-' + index"
                            :value="folder.id"
                            :title="folder.title"
                            @click="moveDocument(folder)"
                        >
                            <template #prepend>
                                <v-icon class="mx-4">mdi-folder-outline</v-icon>
                            </template>
                        </v-list-item>
                    </v-list>

                    <div v-else class="px-8 py-6">{{ $t('MyDocuments.moveDocument.noFolders') }}</div>
                </v-card-text>

                <v-card-actions>
                    <v-btn 
                        variant="text"
                        @click="showMoveDocument = false"
                    >
                        {{ $t('OneWord.back') }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        variant="outlined"
                        color="primary"
                        data-cy="btn-next"
                        type="submit"
                        @click="showNewFolder = true"
                    >
                        {{ $t('MyDocuments.newFolder') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog 
            v-model="showNewFolder"
            data-cy="dialog-new-folder"
            :fullscreen="$vuetify.display.mdAndDown"
            max-width="400"
        >
            <v-card>
                <v-card-title class="bg-primary-lighten-2 text-white">
                    <span v-if="selectedFolder.id">{{ $t('MyDocuments.editFolder') }}</span>
                    <span v-else>{{ $t('MyDocuments.addFolder') }}</span>
                    <v-spacer />
                    <v-btn 
                        icon="mdi-close" 
                        size="small" 
                        variant="text" 
                        class="absolute right-0 top-0 m-1"
                        @click="closeNewFolderDialogue" 
                    />
                </v-card-title>
                
                <vee-form ref="createFolderForm" @submit="saveFolder">
                    <v-card-text class="pa-0 m-0">
                        <vee-field
                            v-slot="{ componentField, errors }"
                            v-model="selectedFolder.title"
                            :label="$t('MyDocuments.folderLabel')"
                            rules="required"
                            name="title"
                        >
                            <v-text-field
                                v-bind="componentField"
                                :error-messages="errors"
                                :label="$t('MyDocuments.folderLabel')"
                                :hint="$t('MyDocuments.folderSample')"
                                :autofocus="!selectedFolder.title"
                                data-cy="input-folder-title"
                                persistent-hint
                                class="m-4"
                            />
                        </vee-field>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn 
                            variant="text"
                            data-cy="btn-back"
                            @click="closeNewFolderDialogue"
                        >
                            {{ $t('OneWord.back') }}
                        </v-btn>
                        <v-spacer />
                        <v-btn 
                            v-if="selectedFolder.id"
                            color="error"
                            variant="outlined"
                            data-cy="btn-delete"
                            @click="deleteFolder"
                        >
                            {{ $t('OneWord.delete') }}
                        </v-btn>
                        <v-btn
                            color="primary"
                            data-cy="btn-next"
                            type="submit"
                        >
                            {{ selectedFolder.id ? $t('OneWord.save') : $t('OneWord.add') }}
                        </v-btn>
                    </v-card-actions>
                </vee-form>
            </v-card>
        </v-dialog>

        <v-alert v-if="folders.length === 1" type="info" data-cy="alert-folder-info">
            <span>{{ $t('MyDocuments.folderInfo') }}</span>
        </v-alert>
    </div>
</template>

<script>
import MyDocument from './MyDocument';
import {EventBus} from '@/utils/event-bus';
import {mapActions} from 'vuex';
import {Form as VeeForm, Field as VeeField} from 'vee-validate';

export default {
    name: 'MyDocuments',

    components: {
        MyDocument,
        VeeField,
        VeeForm,
    },

    data() {
        return {
            panels: [0],
            showMoveDocument: false,
            showNewFolder: false,
            documents: [],
            folders: [],
            selectedDocument: null,
            selectedFolder: {},
            loading: false,
        };
    },

    computed: {
        movableFolders() {
            return _.reject(this.folders, {id: this.selectedDocument?.folder?.id || 0})
        },
        
        latestCreated() {
            return _.maxBy(this.documents, document => moment(document.created_at).valueOf());
        },
    },

    mounted() {
        this.load().then(() => {
            if (
                this.latestCreated && 
                this.latestCreated.folder &&
                moment(this.latestCreated.created_at).isAfter(moment().subtract(1, 'hour'))
            ) {
                // open the panel with the document created last, but only once after mounted
                this.openPanel(_.findIndex(this.folders, {id: this.latestCreated.folder.id}));
            }
        });

        this.resetFolder();
    },

    methods: {
        ...mapActions('user', [
            'setFolders'
        ]),

        load(reloading) {
            if (!reloading) this.loading = true;

            return axios
                .all([
                    axios.get('/webapi/user/document-folders'),
                    axios.get('/webapi/user/my-documents'),
                ])
                .then(axios.spread((folders, documents) => {
                    this.folders = folders.data.data;
                    this.documents = documents.data.data;
                    
                    // sets/updates the folders globally on the user store
                    this.setFolders(_.cloneDeep(folders.data.data));

                    // adds the default folder for the current user
                    this.folders.unshift({id: 0, title: this.$t('OneWord.myDocuments')});
                }))
                .finally(() => {
                    this.loading = false;
                });
        },

        openPanel(index) {
            this.panels = [index];
        },

        saveFolder() {
            axios({
                url: '/webapi/user/document-folders/' + (this.selectedFolder.id ?? ''),
                method: this.selectedFolder.id ? 'put' : 'post',
                data: this.selectedFolder,
            }).then(response => {
                this.closeNewFolderDialogue();
                this.load().then(() => {
                    if (response.data.folder && !this.showMoveDocument) {
                        // open the expansion panel for the newly added folder
                        this.openPanel(_.findIndex(this.folders, {id: response.data.folder.id}));
                    }
                });
                EventBus.$emit('success');
            }).catch(error => {
                if (_.has(error, 'response.data.errors')) {
                    this.$refs.createFolderForm.setErrors(_.get(error, 'response.data.errors'));
                }
            });
        },

        deleteFolder() {
            this.$root.$confirm(
                this.$t('MyDocuments.deleteFolder.title'),
                this.$t('MyDocuments.deleteFolder.description'),
            ).then(confirm => {
                if (confirm) {
                    axios.delete('/webapi/user/document-folders/' + this.selectedFolder.id).then(() => {
                        this.closeNewFolderDialogue();
                        this.load();
                        this.openPanel(0);
                        EventBus.$emit('success');
                    });
                }
            });
        },

        moveDocument(folder) {
            axios.put('/webapi/user/my-documents/move', {
                document: this.selectedDocument,
                folder: folder
            }).then(() => {
                this.closeMoveDocumentDialogue();
                this.load();
                EventBus.$emit('success');
            });
        },

        editFolder(folder) {
            this.selectedFolder = _.cloneDeep(folder);
            this.showNewFolder = true;
        },

        move(document) {
            this.selectedDocument = document;
            this.showMoveDocument = true;
        },

        closeMoveDocumentDialogue() {
            this.selectedDocument = null;
            this.showMoveDocument = false;
        },

        closeNewFolderDialogue() {
            this.resetFolder();
            this.showNewFolder = false;
        },

        resetFolder() {
            this.selectedFolder = {
                id: null,
                title: null,
            };
        },

        filterDocuments(folder, type) {
            return _.orderBy(_.filter(this.documents, document => {
                if (folder.id === 0) {
                    return document.folder === null && document.type === type;
                } else {
                    return document.folder?.id === folder.id && document.type === type;
                }
            }), 'created_at', 'desc');
        },
    },
};
</script>

<style scoped>
.v-expansion-panel-title--active {
    color: #fff;
    background: var(--da-accent);
}

.v-overlay :deep(.v-list-item__prepend) {
    display: inline-block;
}
</style>
