<template>
    <v-alert
        v-if="isAuthenticated && hasProminentNotification"
        density="compact"
        type="info"
        data-cy="prominent-notification"
        variant="flat"
    >
        <div class="d-flex align-center">
            {{ ld.get(getProminentNotification, 'data.subject') }}
            <v-spacer />
            <v-btn
                class="align-self-end"
                color="primary"
                variant="flat"
                size="small"
                @click="openNotification(getProminentNotification)"
            >
                {{ $t('OneWord.show') }}
            </v-btn>
        </div>
    </v-alert>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {EventBus} from "@/utils/event-bus";

export default {
    name: "TheProminentNotification",
    data()  {
        return {
            ld: _,
        };
    },
    computed: {
        ...mapGetters('env', [
            'isAuthenticated'
        ]),
        ...mapGetters('notifications', [
            'getUnreadCount',
            'getProminentNotification',
        ]),
        hasProminentNotification() {
            return this.getUnreadCount > 0 && !_.isUndefined(this.getProminentNotification);
        },
    },
    mounted() {
        if (this.isAuthenticated) {
            this.loadNotifications();
        }

        EventBus.$on('logged-in', this.reloadNotifications);
    },
    methods: {
        ...mapActions('notifications', {
            loadNotifications: 'load',
            reloadNotifications: 'reload',
        }),
        openNotification(item) {
            axios.put(`/webapi/user/notifications/mark-read`, {id: item.id}).then(() => {
                this.$router.push({name: 'admin-news', query: {item: _.get(item, 'data.news_id')}});
                this.reloadNotifications();
            });
        },
    },
}
</script>

<style scoped>

</style>