import router from '@/router';

const initialState = {
    step: 0,
    visitedSteps: [0],
    completed: false,
    agreementAccepted: false,
    ngoThanksSubmitted: false,

    advanceCareDirective: {},
};

const state = _.cloneDeep({...initialState, ...{
    // Define here all properties which should
    // not be reset by the resetState method:
    folderId: null,
}});

const getters = {
    getAdvanceCareDirective: state => state.advanceCareDirective,
    
    // general tool getters
    step: state => state.step,
    visitedSteps: state => state.visitedSteps,
    completed: state => state.completed,
    folderId: state => state.folderId,
    ngoThanksSubmitted: state => state.ngoThanksSubmitted,
    agreementAccepted: state => state.agreementAccepted,
};

const actions = {
    set: ({commit}, value) => commit('set', value),
    patch: ({commit}, value) => commit('patch', value),
    setStep: ({commit}, value) => commit('step', value),
    setCompleted: ({commit}) => commit('setCompleted'),
    setNgoThanksSubmitted: ({commit}) => commit('setNgoThanksSubmitted'),
    setFolderId: ({commit}, value) => commit('setFolderId', value),
    setAgreementAccepted: ({commit}, value) => commit('setAgreementAccepted', value),
    reset: ({commit}) => commit('resetState'),
    restart: ({commit}) => commit('restartTool'),
};

const mutations = {
    set(state, value) {
        state.advanceCareDirective = value;
    },
    patch(state, value) {
        state.advanceCareDirective = _.merge(state.advanceCareDirective, value);
    },
    step(state, value) {
        state.step = parseInt(value);

        if (state.step !== _.last(state.visitedSteps)) {
            state.visitedSteps.push(state.step);
        }
    },
    setCompleted(state) {
        state.completed = true;
    },
    setFolderId(state, value) {
        state.folderId = isNaN(parseInt(value)) ? null : parseInt(value);
    },
    setNgoThanksSubmitted(state) {
        state.ngoThanksSubmitted = true;
    },
    setAgreementAccepted(state, value) {
        state.agreementAccepted = value;
    },
    resetState(state) {
        Object.assign(state, _.cloneDeep(initialState));
    },
    restartTool(state) {
        mutations.resetState(state);
        router.push({name: 'advance-care-directive-tool'});
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};