<template>
    <v-menu
        v-if="isAuthenticated"
        v-model="notificationsMenu"
        :close-on-content-click="false"
    >
        <template #activator="{ props }">
            <v-btn
                class="hidden-sm-and-down text-none"
                data-cy="notifications-menu-btn"
                variant="outlined"
                v-bind="props"
            >
                <v-icon start>mdi-bell-outline</v-icon>
                {{ $t('AppBar.notifications') }}
                <v-chip
                    v-if="getUnreadCount > 0"
                    class="ml-2"
                    data-cy="notifications-menu-btn-chip"
                    size="small"
                    color="red"
                    variant="flat"
                    v-html="getUnreadCount"
                />
            </v-btn>
        </template>

        <v-card>
            <v-card-subtitle class="border-b pb-2 pt-3">
                <v-row align="center" no-gutters>
                    <v-col class="me-auto" cols="auto">
                        <v-card-title class="text-subtitle-2 p-0">
                            {{ $t('AppBar.notifications') }}
                        </v-card-title>
                    </v-col>
                    <v-col cols="auto">
                        <a class="text-blue-800 underline" href="#" data-cy="whats-new" @click.prevent="showNews()">{{ $t('NotificationsMenu.whatsNew') }}</a>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <v-list class="py-0">
                <v-virtual-scroll
                    :bench="5"
                    :items="getNotifications"
                    height="300"
                    item-height="60"
                    width="400"
                    data-cy="notifications-menu-items"
                >
                    <template #default="{ item }">
                        <v-list-item
                            :key="item.id"
                            :class="{'mark-unread': isUnread(item)}"
                            data-cy="notifications-menu-item"
                            @click="openNotification(item)"
                        >
                            <v-list-item-title class="text-ellipsis">
                                {{ getSubject(item) }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ $filters.formatDate(item.created_at) }} 
                                <a 
                                    v-if="!isUnread(item)" 
                                    href="#"
                                    class="item--delete text-blue-800 underline"
                                    @click.prevent.stop="removeNotification(item)"
                                >{{ $t('OneWord.remove') }}</a>
                            </v-list-item-subtitle>
                        </v-list-item>
                    </template>
                </v-virtual-scroll>
            </v-list>
            <v-card-subtitle class="border-t pb-3 pt-2">
                <v-row align="center" no-gutters>
                    <v-col class="me-auto" cols="auto">
                        {{ getUnreadCount }}/{{ getNotifications.length }} {{ $t('OneWord.unread') }}
                    </v-col>
                    <v-col cols="auto">
                        <a class="text-blue-800 underline" href="#" data-cy="mark-all-read" @click.prevent="markAllRead()">{{ $t('OneWord.markAllRead') }}</a>
                    </v-col>
                </v-row>
            </v-card-subtitle>
        </v-card>
    </v-menu>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {EventBus} from "@/utils/event-bus";

export default {
    name: "TheNotificationsMenu",
    data() {
        return {
            notificationsMenu: false,
            ld: _,
        };
    },
    computed: {
        ...mapGetters('env', [
            'isAuthenticated',
        ]),
        ...mapGetters('notifications', [
            'getNotifications',
            'getUnreadCount',
        ]),
    },
    mounted() {
        if (this.isAuthenticated) {
            this.loadNotifications();
        }

        EventBus.$on('logged-in', this.reloadNotifications);
    },
    methods: {
        ...mapActions('notifications', {
            reloadNotifications: 'reload',
            loadNotifications: 'load',
        }),
        openNotification(item) {
            axios.put(`/webapi/user/notifications/mark-read`, {id: item.id}).then(() => {
                this.reloadNotifications();
                this.$router.push({name: 'admin-news', query: {item: _.get(item, 'data.news_id')}});
            });

            this.notificationsMenu = false;
        },
        removeNotification(item) {
            axios.delete('/webapi/user/notifications/' + item.id).then(() => {
                this.reloadNotifications();
            });
        },
        markAllRead() {
            axios.put('/webapi/user/notifications/mark-all-read').then(() => {
                this.reloadNotifications();
            });

            this.notificationsMenu = false;
        },
        getSubject(item) {
            return _.get(item, 'data.subject');
        },
        isUnread(item) {
            return _.isNull(item.read_at);
        },
        showNews() {
            this.$router.push({name: 'admin-news'});
            this.notificationsMenu = false;
        },
    },
}
</script>

<style scoped lang="scss">
.v-list {
    .v-list-item {
        height: 60px;

        .item--delete {
            @apply ml-3;
            display: none;
        }

        &:hover .item--delete {
            display: inline-block;
        }
    }

    .v-list-item__content {
        max-width: 300px;
    }

    .mark-unread {
        @apply border-l-4 border-blue-400 bg-blue-100;
    }

    .v-chip {
        position: absolute;
        right: 10px;
    }
}

.notifications--header {
    padding-bottom: 8px;
    border-bottom: 1px solid #eee;
}

.notifications--footer {
    padding-top: 8px;
    border-top: 1px solid #eee;
}
</style>